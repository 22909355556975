import { useState, useEffect } from "react";
import {
  userClickMapPendingKeyAction,
  updateDSKModalData,
} from "../webhid/action";
import headImg from "../icons/容器.png";
import deleteImg from "../icons/删除@2x.png";
import imgUrl from "../icons/image@1x.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFastTriggerTravelValue } from "../webhid/action";

const SOCD = ({
  firstKey,
  secondKey,
  onClose,
  editingData,
  editingIndex,
  isLight,
}) => {
  const [snappy, setSnappy] = useState(editingData ? true : false);
  const [behaviorMode, setBehaviorMode] = useState(
    editingData ? editingData.behaviorMode : 0
  );
  const [isFastTriggerEnabled, setIsFastTriggerEnabled] = useState(
    editingData ? editingData.isFastTriggerEnabled : false
  );
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const common = useSelector((state) => state.persistent.common);
  const device = common.currentConfigName;
  const deviceConfigures = common[device];
  // console.log('deviceConfigures', deviceConfigures)
  const fastTrigger = deviceConfigures.fastTrigger;
  const [socdTravelValue, setSocdTravelValue] = useState(
    editingData ? editingData.socdTravelValue : fastTrigger.travelValue
  );
  const [pressedSocdKeys, setPressedSocdKeys] = useState([]); // 记录 KeyDown
  const [releasedSocdKeys, setReleasedSocdKeys] = useState([]);
  const [socdKeyHistory, setSocdKeyHistory] = useState([]);
  const [socdKeyCount, setSocdKeyCount] = useState({});
  const [socdKeyDownHistory, setSocdKeyDownHistory] = useState([]);
  const [socdKeyDownCount, setSocdKeyDownCount] = useState({});

  // console.log('SOCD Modal Keys:', { firstKey, secondKey });

  useEffect(() => {
    if (firstKey && secondKey) {
      const updatedSelectedKeys = {
        keyboard: firstKey,
        socdkey: secondKey,
        type: "socd",
        isFastTriggerEnabled: isFastTriggerEnabled,
        behaviorMode: behaviorMode,
        socdTravelValue: socdTravelValue,
      };

      console.log("isFastTriggerEnabled", updatedSelectedKeys);
      dispatch(
        updateDSKModalData(
          "socd",
          updatedSelectedKeys,
          updatedSelectedKeys.keyboard
        )
      );
    }
  }, [isFastTriggerEnabled, behaviorMode]);

  useEffect(() => {
    const newValue = {
      travelValue: socdTravelValue,
    };
    if (firstKey && secondKey) {
      console.log("firstKey", firstKey, secondKey);
      dispatch({
        type: "UPDATE_KEY_VALUES",
        payload: {
          keyId: firstKey,
          values: newValue,
        },
      });
      dispatch({
        type: "UPDATE_KEY_VALUES",
        payload: {
          keyId: secondKey,
          values: newValue,
        },
      });
    }
  }, [socdTravelValue]);

  useEffect(() => {
    // const handleKeyDown = (event) => {
    //     event.preventDefault();

    //     setPressedSocdKeys((prevKeys) => {
    //         if (!prevKeys.includes(event.key)) {
    //             return [...prevKeys, event.key];
    //         }
    //         return prevKeys; // 避免不必要的状态更新
    //     });
    // };

    const handleKeyDown = (event) => {
      event.preventDefault();

      // 更新按键历史
      setSocdKeyDownHistory((prev) => [...prev, event.key]);

      // 更新按键计数
      setSocdKeyDownCount((prev) => ({
        ...prev,
        [event.key]: (prev[event.key] || 0) + 1,
      }));

      setPressedSocdKeys((prevKeys) => {
        if (!prevKeys.includes(event.key)) {
          // 拼接所有历史按键，并显示每个按键的次数
          const allKeys = [...prevKeys, event.key];
          const keyWithCounts = allKeys.map((k) => `${k}`).join(" ");
          return [keyWithCounts];
        }
        return prevKeys;
      });
    };

    // const handleKeyUp = (event) => {
    //     event.preventDefault();

    //     setReleasedSocdKeys((prevKeys) => {
    //         if (!prevKeys.includes(event.key)) {
    //             return [...prevKeys, event.key];
    //         }
    //         return prevKeys; // 避免不必要的状态更新
    //     });
    // };

    const handleKeyUp = (event) => {
      event.preventDefault();

      // 更新按键历史
      setSocdKeyHistory((prev) => [...prev, event.key]);

      // 更新按键计数
      setSocdKeyCount((prev) => ({
        ...prev,
        [event.key]: (prev[event.key] || 0) + 1,
      }));

      setReleasedSocdKeys((prevKeys) => {
        if (!prevKeys.includes(event.key)) {
          // 拼接所有历史按键，并显示每个按键的次数
          const allKeys = [...prevKeys, event.key];
          const keyWithCounts = allKeys.map((k) => `${k}`).join(" ");
          return [keyWithCounts];
        }
        return prevKeys;
      });
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // 每1000毫秒清空输入框
    const interval = setInterval(() => {
      setPressedSocdKeys([]);
      setReleasedSocdKeys([]);
    }, 2000);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      clearInterval(interval); // 组件卸载时清除定时器
    };
  }, []);

  return (
    <>
      {!snappy && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
            pointerEvents: "none",
          }}
        >
          <div
            className="modal-content"
            style={{
              position: "fixed",
              // background: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              zIndex: 1000,
              width: "60%",
              height: "35%",
              top: "55%",
              left: "55%",
              transform: "translateX(-50%)",
              pointerEvents: "auto",
              backgroundColor: isLight ? "white" : "#252525",
              color: isLight ? "black" : "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span>{t("snappy_tappy")}</span>
                  <img
                    src={headImg}
                    alt="帮助"
                    style={{
                      width: "16px", // 控制图片大小
                      height: "16px",
                      cursor: "pointer",
                    }}
                  />
                </div>

                <p
                  style={{
                    fontSize: "12px",
                    color: "#525252",
                    lineHeight: "1.5",
                    marginTop: "5px",
                    width: "70%",
                    textAlign: "center",
                    whiteSpace: "pre-line",
                  }}
                >
                  <span>{t("socd_technology")}</span>
                </p>
              </div>

              {/* 按钮区域 */}
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                {/* 删除按钮 */}
                {/* <button
                                style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                    color: "#666",
                                }}
                                onClick={onClose}
                            >
                                <img
                                    src={deleteImg}
                                    alt="删除"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                    }}
                                />
                            </button> */}

                {/* 取消按钮 */}
                <button
                  style={{
                    backgroundColor: isLight ? "#E0E0E0" : "#393939",
                    border: "none",
                    padding: "6px 16px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "14px",
                    marginTop: "-5px",
                    color: isLight ? "black" : "white",
                  }}
                  onClick={() => {
                    dispatch(userClickMapPendingKeyAction([]));
                    onClose();
                    setSnappy(false);
                  }}
                >
                  {t("cancel")}
                </button>

                {/* 继续按钮 */}
                <button
                  style={{
                    backgroundColor: "#4369ff",
                    color: "white",
                    border: "none",
                    padding: "6px 16px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "14px",
                    marginTop: "-5px",
                  }}
                  onClick={() => {
                    if (firstKey && secondKey) {
                      console.log("SOCD Modal Keys:", { firstKey, secondKey });

                      const updatedSelectedKeys = {
                        keyboard: firstKey,
                        socdkey: secondKey,
                        type: "socd",
                        isFastTriggerEnabled,
                        behaviorMode,
                        socdTravelValue,
                      };

                      dispatch(
                        updateDSKModalData(
                          "socd",
                          updatedSelectedKeys,
                          updatedSelectedKeys.keyboard
                        )
                      );
                    }
                    setSnappy(true);
                  }}
                >
                  {t("continue")}
                </button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5vh",
                width: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#666",
                      marginBottom: "5px",
                    }}
                  >
                    {t("key_one")}
                  </span>
                  <input
                    type="text"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      textAlign: "center",
                      marginTop: "10px",
                      border: isLight ? "1px solid #ddd" : "1px solid #2f2f2f",
                      backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                      color: isLight ? "black" : "white",
                    }}
                    value={firstKey || ""}
                    disabled
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#666",
                      marginBottom: "5px",
                    }}
                  >
                    {t("key_two")}
                  </span>
                  <input
                    type="text"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "5px",
                      border: isLight ? "1px solid #ddd" : "1px solid #2f2f2f",
                      backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                      color: isLight ? "black" : "white",
                      fontSize: "14px",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                    value={secondKey || ""}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {snappy && (
        <>
          <div
            className="modal-overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999,
            }}
          >
            <div
              className="modal-content"
              style={{
                position: "fixed",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1000,
                width: "60%",
                height: "35%",
                top: "55%",
                left: "55%",
                transform: "translateX(-50%)",
                backgroundColor: isLight ? "white" : "#252525",
                color: isLight ? "black" : "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>{t("snappy_tappy")}</span>
                    <img
                      src={headImg}
                      alt="帮助"
                      style={{
                        width: "16px", // 控制图片大小
                        height: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>

                {/* 按钮区域 */}
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  {/* 删除按钮 */}
                  {/* <button
                                        style={{
                                            background: "none",
                                            border: "none",
                                            cursor: "pointer",
                                            fontSize: "18px",
                                            color: "#666",
                                        }}
                                        onClick={onClose}
                                    >
                                        <img
                                            src={deleteImg}
                                            alt="删除"
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </button> */}

                  {/* 取消按钮 */}
                  <button
                    style={{
                      backgroundColor: isLight ? "#E0E0E0" : "#393939",
                      color: isLight ? "black" : "white",
                      border: "none",
                      padding: "6px 16px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "-5px",
                    }}
                    onClick={() => {
                      dispatch(userClickMapPendingKeyAction([]));
                      onClose();
                      setSnappy(false);
                    }}
                  >
                    {t("cancel")}
                  </button>

                  {/* 继续按钮 */}
                  <button
                    style={{
                      backgroundColor: "#4369ff",
                      color: "white",
                      border: "none",
                      padding: "6px 16px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: "-5px",
                    }}
                    onClick={() => {
                      if (firstKey && secondKey) {
                        console.log("SOCD Modal Keys:", {
                          firstKey,
                          secondKey,
                        });
                        onClose({
                          keyboard: firstKey,
                          socdkey: secondKey,
                          type: "socd",
                          isFastTriggerEnabled: isFastTriggerEnabled,
                          behaviorMode: behaviorMode,
                          socdTravelValue: socdTravelValue,
                        });
                      }
                      setSnappy(false);
                    }}
                  >
                    {t("continue")}
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2vh",
                }}
              >
                {/* 行为模式 */}
                <div style={{ width: "30%" }}>
                  <p style={{ fontSize: "13px", marginBottom: "10px" }}>
                    {t("behavior_mode")}
                  </p>
                  {[
                    { label: t("last_input_priority"), value: 0 },
                    { label: t("absolute_key_one"), value: 1 },
                    { label: t("absolute_key_two"), value: 2 },
                    { label: t("cancel_mode"), value: 3 },
                  ].map((item, index) => (
                    <label
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #2f2f2f",
                        // background: behaviorMode === item.value ? "#e6f3ff" : "#f2f4f5",
                        background:
                          behaviorMode === item.value
                            ? isLight
                              ? "#e6f3ff"
                              : "#2f2f2f"
                            : isLight
                            ? "#e6f3ff"
                            : "#2f2f2f",
                        padding: "10px",
                        borderRadius: "8px",
                        marginBottom: "2vh",
                        cursor: "pointer",
                        fontSize: "13px",
                        color: isLight ? "black" : "#707070",
                      }}
                      onClick={() => setBehaviorMode(item.value)}
                    >
                      <input
                        type="radio"
                        name="mode"
                        checked={behaviorMode === item.value}
                        onChange={() => setBehaviorMode(item.value)}
                        style={{ marginRight: "10px" }}
                      />
                      {item.label}
                    </label>
                  ))}
                </div>

                {/* 快速触发 */}
                <div style={{ width: "35%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "1.5vh",
                    }}
                  >
                    <span style={{ fontSize: "13px", marginLeft: "2vh" }}>
                      {t("rapid_trigger")}
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isFastTriggerEnabled}
                        onChange={() =>
                          setIsFastTriggerEnabled(!isFastTriggerEnabled)
                        }
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#666",
                      marginBottom: "3vh",
                      whiteSpace: "pre-line",
                      marginLeft: "2vh",
                      marginRight: "10vh",
                    }}
                  >
                    {t("activating_rapid_trigger")}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#666",
                      marginBottom: "2vh",
                      whiteSpace: "pre-line",
                      marginLeft: "2vh",
                      marginRight: "8vh",
                    }}
                  >
                    {t("dept_of_key_press")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "-12vh",
                    }}
                  >
                    {/* 图片 */}
                    <img
                      src={imgUrl}
                      alt=""
                      style={{
                        width: "90px",
                        height: "90px",
                        marginRight: "5vh",
                      }}
                    />

                    {/* 竖向滑块 + 数值显示 */}
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="range"
                        min="0.10"
                        max="3.50"
                        step="0.01"
                        defaultValue="1.50"
                        value={socdTravelValue}
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value);
                          setSocdTravelValue(newValue);
                          dispatch(setFastTriggerTravelValue(newValue));
                        }}
                        style={{
                          writingMode: "vertical-lr",
                          width: "3px",
                          height: "100px",
                          marginBottom: "1vh",
                          accentColor: "#4e6dd0",
                        }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          left: "20px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          fontSize: "14px",
                          color: isLight ? "#666" : "white",
                          // background: "rgba(255,255,255,0.8)",
                        }}
                      >
                        {socdTravelValue.toFixed(2) + "mm"}
                      </span>
                    </div>
                  </div>
                </div>

                {/* 测试你的绑定 */}
                <div style={{ width: "30%" }}>
                  <p style={{ fontSize: "13px", marginBottom: "10px" }}>
                    {t("test_your_bindings")}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#666",
                      marginBottom: "5px",
                    }}
                  >
                    {t("pressed_key")}
                  </p>
                  <input
                    type="text"
                    value={pressedSocdKeys.join("  ")}
                    style={{
                      width: "90%",
                      height: "50px",
                      border: isLight
                        ? "1px solid #f2f4f5"
                        : "1px solid #2f2f2f",
                      borderRadius: "5px",
                      backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                      fontSize: "14px",
                      textAlign: "center",
                      marginBottom: "10px",
                      color: isLight ? "black" : "white",
                    }}
                    disabled
                  />

                  <p
                    style={{
                      fontSize: "12px",
                      color: "#666",
                      marginBottom: "5px",
                    }}
                  >
                    {t("released_key")}
                  </p>
                  <input
                    type="text"
                    value={releasedSocdKeys.join("  ")}
                    style={{
                      width: "90%",
                      height: "50px",
                      border: isLight
                        ? "1px solid #f2f4f5"
                        : "1px solid #2f2f2f",
                      borderRadius: "5px",
                      backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                      fontSize: "14px",
                      textAlign: "center",
                      color: isLight ? "black" : "white",
                    }}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SOCD;
