import { useState, useEffect, useCallback, useRef } from "react";
import ButtonUnit from "./ButtonUnit";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllPopupAction,
  sendFatalLogAction,
  userClickKeyboardBelowAction,
} from "../webhid/action";
import {
  getShowTextByKeyCode,
  getUidByKeyCode,
  keyMapData,
} from "../webhid/KeyboardKeyData";
import headImg from "../icons/容器.png";
import deleteImg from "../icons/删除@2x.png";
import downImg from "../icons/按下.png";
import plusImg from "../icons/组 64@2x.png";
import upImg from "../icons/上@2x.png";
import isUpImg from "../icons/按上@2x.png";
import down2Img from "../icons/下@2x.png";
import isDownImg from "../icons/按下@2x.png";
import selectPlusImg from "../icons/组 162@2x.png";
import { useTranslation } from "react-i18next";
import imgUrl from "../icons/image@1x.png";
import {
  userClickMapPendingKeyAction,
  updateDSKModalData,
} from "../webhid/action";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const DSK = ({ type, onClose, editingData, editingIndex, isLight }) => {
  //   const rows = 4;
  //   const cols = 4; // 4 列按钮 + 1 列输入框
  //   const totalButtons = rows * cols;

  const [sliderValue, setSliderValue] = useState(
    editingData?.sliderValue || 80
  );

  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  const device = common.currentConfigName;
  common = common[device];
  const selectedKeyValue = common.keyboardBelowClickValue;
  const selectedKeyUid = common.keyboardBelowClickUid;
  const endNeedSuperKey = common.mapPendingSuperKey;
  // let isLight = common.isLight;

  const nextWidthRef = useRef(1);

  const dispatch = useDispatch();

  nextWidthRef.current = 1;

  // Initialize states based on editingData
  const [selectedKey1, setSelectedKey1] = useState("");
  const [selectedKey2, setSelectedKey2] = useState(
    editingData?.keys?.[1] || ""
  );
  const [originalEndKey, setOriginalEndKey] = useState(
    editingData?.originalKeys?.[1] || ""
  );
  const [tglSelectedKey, setTglSelectedKey] = useState(
    editingData?.keys?.[0] || ""
  );
  const [originalTglSelectedKey, setOriginalTglSelectedKey] = useState(
    editingData?.originalKeys?.[0] || ""
  );
  const [mtSelectedKey1, setMtSelectedKey1] = useState(
    editingData?.keys?.[0] || ""
  );
  const [originalMtSelectedKey1, setOriginalMtSelectedKey1] = useState(
    editingData?.originalKeys?.[0] || ""
  );
  const [mtSelectedKey2, setMtSelectedKey2] = useState(
    editingData?.keys?.[1] || ""
  );
  const [originalMtSelectedKey2, setOriginalMtSelectedKey2] = useState(
    editingData?.originalKeys?.[1] || ""
  );
  const [focusedInput, setFocusedInput] = useState(null);
  const [focusedTGLInput, setFocusedTGLInput] = useState(null);
  const [focusedMTInput, setFocusedMTInput] = useState(null);
  const [mptDis, setMptDis] = useState(editingData?.mptDis || [1.5, 1.5, 1.5]);
  const [showDisModal, setShowDisModal] = useState(false);
  const [mptTravel, setMptTravel] = useState(1.5);
  const [selectedMptIndex, setSelectedMptIndex] = useState(null);
  const [selectedMptKey, setSelectedMptKey] = useState(
    editingData?.keys || ["", "", ""]
  );
  const [originalMptKey, setOriginalMptKey] = useState(
    editingData?.originalKeys || ["", "", ""]
  );
  const [focusedMptInput, setFocusedMptInput] = useState(null);
  const [selectedDSKKey, setSelectedDSKKey] = useState(editingData?.keys || []);
  const [originalDSKKey, setOriginalDSKKey] = useState(
    editingData?.originalKeys || []
  );
  const [focusedDSKInput, setFocusedDSKInput] = useState(null);
  const [selectedDSKDis, setSelectedDSKDis] = useState([
    { value: 1.5, icon: down2Img },
    { value: 3.5, icon: isDownImg },
    { value: 3.5, icon: upImg },
    { value: 1.5, icon: isUpImg },
  ]);
  const [selectedDSKIndex, setSelectedDSKIndex] = useState(null);
  const [dskTravel, setDskTravel] = useState(1.6);

  const handleFocus = (input) => {
    console.log("handleFocus", input);
    setFocusedInput(input);
  };

  const handleTGLFocus = (input) => {
    setFocusedTGLInput(input);
  };

  const handleMTFocus = (input) => {
    setFocusedMTInput(input);
  };

  const handleMptDistanceChange = (index, value) => {
    const newDis = [...mptDis];
    newDis[index] = parseFloat(value); // 更新数组
    setMptDis(newDis);
  };

  const handleMptFocus = (input) => {
    setFocusedMptInput(input);
  };

  const handleDSKFocus = (input) => {
    setFocusedDSKInput(input);
  };

  const handleDSKDisChange = (index, value) => {
    const newDis = [...selectedDSKDis];
    // newDis[index] = {
    //   ...newDis[index],
    //   value: parseFloat(value)
    // };
    newDis[0] = {
      ...newDis[0],
      value: parseFloat(value),
    };
    newDis[3] = {
      ...newDis[3],
      value: parseFloat(value),
    };
    setSelectedDSKDis(newDis);
  };

  // 处理DSK距离点击事件
  const handleDSKDistanceClick = (index) => {
    setSelectedDSKIndex(index);
    setDskTravel(selectedDSKDis[index].value);
    setShowDisModal(true);
  };

  // 更新DSK距离设置模态框中的保存逻辑
  const handleDSKDistanceSave = () => {
    if (selectedDSKIndex !== null) {
      handleDSKDisChange(selectedDSKIndex, dskTravel);
    }
    setShowDisModal(false);
  };

  // 更新DSK距离显示组件
  const renderDSKDistances = () => (
    <div style={{ width: "70%", display: "flex", gap: "10px" }}>
      {selectedDSKDis.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "40px",
            marginLeft: "2vh",
          }}
        >
          <div
            style={{
              border: isLight ? "1px solid #ddd" : "1px solid #2f2f2f",
              borderRadius: "4px",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "8px",
              background: isLight ? "#fff" : "#2f2f2f",
            }}
          >
            <img
              src={item.icon}
              alt="direction"
              style={{ width: "10px", height: "25px" }}
            />
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "#666",
              cursor: index === 0 || index === 3 ? "pointer" : "auto",
              textDecoration: index === 0 || index === 3 ? "underline" : "none",
            }}
            onClick={() => {
              if (index === 0 || index === 3) {
                handleDSKDistanceClick(index);
              }
            }}
          >
            {item.value.toFixed(2) + " mm"}
          </div>
        </div>
      ))}
    </div>
  );

  // 更新DSK距离设置模态框中的保存按钮点击处理
  const handleModalSave = () => {
    if (type === "mpt") {
      if (selectedMptIndex !== null) {
        handleMptDistanceChange(selectedMptIndex, mptTravel);
      }
    } else if (type === "dsk") {
      handleDSKDistanceSave();
    }
    setShowDisModal(false);
  };

  const sliderTimeoutRef = useRef(null); // 用于存储定时器

  // 处理滑块值变化
  const handleSliderChange = (e) => {
    const value = e.target.value;
    setSliderValue(value);

    // 清除之前的定时器
    if (sliderTimeoutRef.current) {
      clearTimeout(sliderTimeoutRef.current);
    }

    // 设置新的定时器，在用户停止拖动 500ms 后执行逻辑
    sliderTimeoutRef.current = setTimeout(() => {
      if (type === "mt" && mtSelectedKey1 && mtSelectedKey2) {
        const updatedSelectedKeys = {
          keyboard: editingData?.keyboard || endNeedSuperKey,
          mtkey1: mtSelectedKey1,
          mtkey2: mtSelectedKey2,
          originalMtkey1: originalMtSelectedKey1,
          originalMtkey2: originalMtSelectedKey2,
          sliderValue: value,
          type: "mt",
        };
        dispatch(
          updateDSKModalData(
            type,
            updatedSelectedKeys,
            updatedSelectedKeys.keyboard
          )
        );
      }
    }, 500); // 500ms 后执行
  };

  // 清理定时器
  useEffect(() => {
    return () => {
      if (sliderTimeoutRef.current) {
        clearTimeout(sliderTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (type === "mt" && mtSelectedKey1 && mtSelectedKey2) {
      const updatedSelectedKeys = {
        keyboard: editingData?.keyboard || endNeedSuperKey,
        mtkey1: mtSelectedKey1,
        mtkey2: mtSelectedKey2,
        sliderValue: sliderValue,
        originalMtkey1: originalMtSelectedKey1,
        originalMtkey2: originalMtSelectedKey2,
        type: "mt",
      };

      dispatch(
        updateDSKModalData(
          type,
          updatedSelectedKeys,
          updatedSelectedKeys.keyboard
        )
      );
    }
  }, [type === "mt", mtSelectedKey1, mtSelectedKey2]);

  useEffect(() => {
    if (type === "tgl" && tglSelectedKey) {
      const updatedSelectedKeys = {
        keyboard: editingData?.keyboard || endNeedSuperKey,
        tglkey: tglSelectedKey,
        originalTglkey: originalTglSelectedKey,
        type: "tgl",
      };
      console.log("tgl", updatedSelectedKeys);
      dispatch(
        updateDSKModalData(
          type,
          updatedSelectedKeys,
          updatedSelectedKeys.keyboard
        )
      );
    }
  }, [type === "tgl", tglSelectedKey]);

  useEffect(() => {
    if (type === "end" && selectedKey2) {
      console.log("selectedKey2343534", selectedKey2);
      const updatedSelectedKeys = {
        keyboard: editingData?.keyboard || endNeedSuperKey,
        key1: editingData?.keyboard || endNeedSuperKey,
        key2: selectedKey2,
        originalKey: originalEndKey,
        type: "end",
      };
      dispatch(
        updateDSKModalData(
          type,
          updatedSelectedKeys,
          updatedSelectedKeys.keyboard
        )
      );
    }
  }, [type === "end", selectedKey2]);

  useEffect(() => {
    if (
      type === "mpt" &&
      selectedMptKey[0] &&
      selectedMptKey[1] &&
      selectedMptKey[2]
    ) {
      const updatedSelectedKeys = {
        keyboard: editingData?.keyboard || endNeedSuperKey,
        mptkey1: selectedMptKey[0],
        mptkey2: selectedMptKey[1],
        mptkey3: selectedMptKey[2],
        originalMptkey1: originalMptKey[0],
        originalMptkey2: originalMptKey[1],
        originalMptkey3: originalMptKey[2],
        mptDis1: mptDis[0],
        mptDis2: mptDis[1],
        mptDis3: mptDis[2],
        type: "mpt",
      };
      console.log("updatedSelectedKeys", updatedSelectedKeys);
      dispatch(
        updateDSKModalData(
          type,
          updatedSelectedKeys,
          updatedSelectedKeys.keyboard
        )
      );
    }
  }, [selectedMptKey[0], selectedMptKey[1], selectedMptKey[2]]);

  useEffect(() => {
    if (
      type === "mpt" &&
      selectedMptKey[0] &&
      selectedMptKey[1] &&
      selectedMptKey[2]
    ) {
      const updatedSelectedKeys = {
        keyboard: editingData?.keyboard || endNeedSuperKey,
        mptkey1: selectedMptKey[0],
        mptkey2: selectedMptKey[1],
        mptkey3: selectedMptKey[2],
        originalMptkey1: originalMptKey[0],
        originalMptkey2: originalMptKey[1],
        originalMptkey3: originalMptKey[2],
        mptDis1: mptDis[0],
        mptDis2: mptDis[1],
        mptDis3: mptDis[2],
        type: "mpt",
      };
      dispatch(
        updateDSKModalData(
          type,
          updatedSelectedKeys,
          updatedSelectedKeys.keyboard
        )
      );
    }
  }, [mptDis[0], mptDis[1], mptDis[2]]);

  const [pressedKeys, setPressedKeys] = useState([]); // 记录 KeyDown
  const [releasedKeys, setReleasedKeys] = useState([]);
  const [isTestSectionFocused, setIsTestSectionFocused] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Only track keys if test section is focused
      if (isTestSectionFocused) {
        event.preventDefault();
        const mappedKey = event.key;
        setPressedKeys((prev) => prev + " " + mappedKey);
      }
    };

    const handleKeyUp = (event) => {
      // Only track keys if test section is focused
      if (isTestSectionFocused) {
        event.preventDefault();
        const mappedKey = event.key;
        setReleasedKeys((prev) => prev + " " + mappedKey);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // 每1000毫秒清空输入框
    const interval = setInterval(() => {
      if (isTestSectionFocused) {
        setPressedKeys([]);
        setReleasedKeys([]);
      }
    }, 2000);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      clearInterval(interval); // 组件卸载时清除定时器
    };
  }, [isTestSectionFocused]);

  const rows = 4;
  const cols = 4;
  const gridSize = 40;
  const imgSize = 25;
  const pathHeight = 26;

  const [gridValues, setGridValues] = useState(
    Array(rows)
      .fill()
      .map(() => Array(cols).fill("00"))
  );

  // 选中状态，按行管理
  const [selectedButtons, setSelectedButtons] = useState(
    Array(rows)
      .fill()
      .map(() => Array(cols).fill(false))
  );

  // 拖拽路径，按行管理
  const [dragPaths, setDragPaths] = useState(
    Array(rows)
      .fill()
      .map(() => [])
  );

  const [dragEndImages, setDragEndImages] = useState(
    Array(rows)
      .fill()
      .map(() => Array(cols).fill(false))
  );

  const handleSelectClick = (row, col) => {
    const newSelected = [...selectedButtons];
    newSelected[row][col] = !newSelected[row][col]; // 切换选中状态
    setSelectedButtons(newSelected);

    const newGridValues = [...gridValues];
    newGridValues[row][col] = newSelected[row][col] ? "10" : "00";
    setGridValues(newGridValues);
  };

  // **点击起始拖拽图片，取消该路径**
  const handlePathClick = (row, col) => {
    // 过滤掉以 col 作为起点的路径
    const newPaths = dragPaths[row].filter(([start]) => start !== col);
    setDragPaths((prevPaths) => {
      const updatedPaths = [...prevPaths];
      updatedPaths[row] = newPaths;
      return updatedPaths;
    });

    const newGridValues = [...gridValues];
    for (let i = col; i < gridValues[row].length; i++) {
      if (gridValues[row][i] !== "10") {
        newGridValues[row][i] = "00"; // 只有路径部分被改为 "00"
      }
    }
    setGridValues(newGridValues);
  };

  // **处理拖拽**
  const handleDrag = (row, startCol, endCol) => {
    if (endCol >= cols) return; // 不能拖到最后一列
    if (startCol === endCol) return; // 不能拖动到自己

    const midPoint = startCol + 0.5; // 计算中点
    let targetCol = startCol; // 默认不变

    if (endCol > midPoint) {
      targetCol = endCol; // 超过中点，路径终点设为目标图片的前方
    }

    // 记录新的拖拽路径
    const newPaths = [...dragPaths];
    newPaths[row] = [...newPaths[row], [startCol, targetCol]];

    setDragPaths(newPaths);

    const newGridValues = [...gridValues];
    for (let i = startCol; i < targetCol; i++) {
      newGridValues[row][i] = "11"; // 设置覆盖的为 "11"
    }
    setGridValues(newGridValues);

    // 设置拖拽终点的图片为选中状态
    const newDragEndImages = [...dragEndImages];
    newDragEndImages[row][targetCol] = true;
    setDragEndImages(newDragEndImages);
  };

  const [draggingPath, setDraggingPath] = useState(null);

  // 拖拽过程中显示临时路径
  const handleDragOver = (e, row, col) => {
    e.preventDefault();
    const startCol = parseInt(e.dataTransfer.getData("col"), 10);
    if (startCol !== col && startCol < col) {
      setDraggingPath({ row, start: startCol, end: col });
    }
  };

  // 拖拽结束后清除预览路径
  const handleDragEnd = () => {
    setDraggingPath(null);
  };

  useEffect(() => {
    if (editingData?.rowBinary) {
      const newGridValues = Array(rows)
        .fill()
        .map(() => Array(cols).fill("00"));
      const newSelectedButtons = Array(rows)
        .fill()
        .map(() => Array(cols).fill(false));
      const newDragPaths = Array(rows)
        .fill()
        .map(() => []);

      editingData.rowBinary.forEach((binaryRow, row) => {
        const parsedRow = [];

        // **按两位解析**
        for (let i = 0; i < binaryRow.length; i += 2) {
          parsedRow.push(binaryRow.slice(i, i + 2));
        }
        newGridValues[row] = parsedRow;

        let dragStart = -1;

        parsedRow.forEach((value, col) => {
          if (value === "10") {
            newSelectedButtons[row][col] = true; // 选中
          }

          if (value === "11") {
            if (dragStart === -1) {
              dragStart = col; // 记录拖拽起点
            }
          } else {
            if (dragStart !== -1) {
              newDragPaths[row].push([dragStart, col]); // **拖拽终点设为前一个位置**
              dragStart = -1;
            }
          }
        });

        // **处理末尾的拖拽路径**
        if (dragStart !== -1) {
          newDragPaths[row].push([dragStart, parsedRow.length]);
        }
      });

      setGridValues(newGridValues);
      setSelectedButtons(newSelectedButtons);
      setDragPaths(newDragPaths);

      console.log("转换后的 gridValues:", newGridValues);
      console.log("转换后的 selectedButtons:", newSelectedButtons);
      console.log("转换后的 dragPaths:", newDragPaths);
    }
  }, [editingData?.rowBinary]);

  const [prevGridValues, setPrevGridValues] = useState(gridValues);
  const [prevSelectedDSKKey, setPrevSelectedDSKKey] = useState(selectedDSKKey);
  const [prevSelectedDSKDis, setPrevSelectedDSKDis] = useState(selectedDSKDis);

  useEffect(() => {
    const rowBinary = {};

    const newGridValues = JSON.parse(JSON.stringify(gridValues));
    console.log("gridValues", gridValues);
    for (const key in gridValues) {
      const lastIndex = gridValues[key].length - 1;
      if (gridValues[key][lastIndex] === "11") {
        gridValues[key][lastIndex] = "10";
      }
      rowBinary[key] = gridValues[key].join("");
    }
    console.log("rowBinary", rowBinary);

    if (
      type === "dsk" &&
      selectedDSKKey.length !== 0 &&
      (JSON.stringify(prevGridValues) !== JSON.stringify(newGridValues) ||
        JSON.stringify(prevSelectedDSKKey) !== JSON.stringify(selectedDSKKey) ||
        JSON.stringify(prevSelectedDSKDis) !== JSON.stringify(selectedDSKDis))
    ) {
      setPrevGridValues(newGridValues);
      setPrevSelectedDSKKey(selectedDSKKey);
      setPrevSelectedDSKDis(selectedDSKDis);

      const updatedSelectedKeys = {
        keyboard: editingData?.keyboard || endNeedSuperKey,
        dskkey1: selectedDSKKey[0],
        dskkey2: selectedDSKKey[1],
        dskkey3: selectedDSKKey[2],
        dskkey4: selectedDSKKey[3],
        originalDskkey1: originalDSKKey[0],
        originalDskkey2: originalDSKKey[1],
        originalDskkey3: originalDSKKey[2],
        originalDskkey4: originalDSKKey[3],
        dskDis1: selectedDSKDis[0].value,
        dskDis2: selectedDSKDis[1].value,
        dskDis3: selectedDSKDis[2].value,
        dskDis4: selectedDSKDis[3].value,
        rowStates1: rowBinary[0], // 添加行状态
        rowStates2: rowBinary[1],
        rowStates3: rowBinary[2],
        rowStates4: rowBinary[3],
        type: "dsk",
      };

      console.log("updatedSelectedKeys", updatedSelectedKeys);
      dispatch(
        updateDSKModalData(
          type,
          updatedSelectedKeys,
          updatedSelectedKeys.keyboard
        )
      );

      const newValue = {
        travelValue: dskTravel,
      };

      dispatch({
        type: "UPDATE_KEY_VALUES",
        payload: {
          keyId: editingData?.keyboard[0] || endNeedSuperKey[0],
          values: newValue,
        },
      });
    }
  }, [selectedDSKKey, selectedDSKDis, gridValues]);

  const keyMap = [
    [
      { text: "Esc", keycode: "Escape" },
      { text: "F1", keycode: "F1" },
      { text: "F2", keycode: "F2" },
      { text: "F3", keycode: "F3" },
      { text: "F4", keycode: "F4" },
      { text: "F5", keycode: "F5" },
      { text: "F6", keycode: "F6" },
      { text: "F7", keycode: "F7" },
      { text: "F8", keycode: "F8" },
      { text: "F9", keycode: "F9" },
      { text: "F10", keycode: "F10" },
      { text: "F11", keycode: "F11" },
      { text: "F12", keycode: "F12" },
      { text: "Print\\nScreen", keycode: "PrintScreen" },
      { text: "Scroll\\nLock", keycode: "ScrollLock" },
      { text: "Pause\\nBreak", keycode: "Pause" },
      { text: "~\\n`", keycode: "Backquote" },
      { text: "!\\n1", keycode: "Digit1" },
      { text: "@\\n2", keycode: "Digit2" },
      { text: "#\\n3", keycode: "Digit3" },
      { text: "$\\n4", keycode: "Digit4" },
      { text: "%\\n5", keycode: "Digit5" },
      { text: "^\\n6", keycode: "Digit6" },
      { text: "&\\n7", keycode: "Digit7" },
      { text: "*\\n8", keycode: "Digit8" },
      { text: "(\\n9", keycode: "Digit9" },
      { text: ")\\n0", keycode: "Digit0" },
      { text: "_\\n-", keycode: "Minus" },
      { text: "+\\n=", keycode: "Equal" },
      { text: "Backspace", keycode: "Backspace" },
      { text: "Insert", keycode: "Insert" },
      { text: "Home", keycode: "Home" },
      { text: "PgUp", keycode: "PageUp" },
      { text: "Num\\nLock", keycode: "NumLock" },
      { text: "/", keycode: "NumpadDivide" },
      { text: "*", keycode: "NumpadMultiply" },
      { text: "-", keycode: "NumpadSubtract" },
      { text: "Tab", keycode: "Tab" },
      { text: "Q", keycode: "KeyQ" },
      { text: "W", keycode: "KeyW" },
      { text: "E", keycode: "KeyE" },
      { text: "R", keycode: "KeyR" },
      { text: "T", keycode: "KeyT" },
      { text: "Y", keycode: "KeyY" },
      { text: "U", keycode: "KeyU" },
      { text: "I", keycode: "KeyI" },
      { text: "O", keycode: "KeyO" },
      { text: "P", keycode: "KeyP" },
      { text: "{\\n[", keycode: "BracketLeft" },
      { text: "}\\n]", keycode: "BracketRight" },
      { text: "|\\n\\", keycode: "Backslash" },
      { text: "Delete", keycode: "Delete" },
      { text: "End", keycode: "End" },
      { text: "PgDn", keycode: "PageDown" },
      { text: "7\\nHome", keycode: "Numpad7" },
      { text: "8\\n↑", keycode: "Numpad8" },
      { text: "9\\nPgUp", keycode: "Numpad9" },
      { text: "+", keycode: "NumpadAdd" },
      { text: "Caps Lock", keycode: "CapsLock" },
      { text: "A", keycode: "KeyA" },
      { text: "S", keycode: "KeyS" },
      { text: "D", keycode: "KeyD" },
      { text: "F", keycode: "KeyF" },
      { text: "G", keycode: "KeyG" },
      { text: "H", keycode: "KeyH" },
      { text: "J", keycode: "KeyJ" },
      { text: "K", keycode: "KeyK" },
      { text: "L", keycode: "KeyL" },
      { text: ":\\n;", keycode: "Semicolon" },
      { text: "\"\\n'", keycode: "Quote" },
      { text: "Enter", keycode: "Enter" },
      { text: "4\\n←", keycode: "Numpad4" },
      { text: "5", uid: "00 5d", keycode: "Numpad5" },
      { text: "6\\n→", keycode: "Numpad6" },
      { text: "Shift", keycode: "ShiftLeft" },
      { text: "Z", keycode: "KeyZ" },
      { text: "X", keycode: "KeyX" },
      { text: "C", keycode: "KeyC" },
      { text: "V", keycode: "KeyV" },
      { text: "B", keycode: "KeyB" },
      { text: "N", keycode: "KeyN" },
      { text: "M", keycode: "KeyM" },
      { text: "<\\n,", keycode: "Comma" },
      { text: ">\\n.", keycode: "Period" },
      { text: "?\\n/", keycode: "Slash" },
      { text: "Shift", keycode: "ShiftRight" },
      { text: "↑", keycode: "ArrowUp" },
      { text: "1\\nEnd", keycode: "Numpad1" },
      { text: "2\\n↓", keycode: "Numpad2" },
      { text: "3\\nPgDn", keycode: "Numpad3" },
      { text: "Enter", keycode: "NumpadEnter" },
      { text: "Ctrl", keycode: "ControlLeft" },
      { text: "Win", keycode: "WinLeft" },
      { text: "Win", keycode: "MetaLeft" },
      { text: "Alt", keycode: "AltLeft" },
      { text: "Space", keycode: "Space" },
      { text: "Alt", keycode: "AltRight" },
      { text: "Win", keycode: "WinRight" },
      { text: "Win", keycode: "MetaRight" },
      { text: "Fn", keycode: "ControlRight" },
      { text: "Ctrl", keycode: "ControlRight" },
      { text: "←", keycode: "ArrowLeft" },
      { text: "↓", keycode: "ArrowDown" },
      { text: "→", keycode: "ArrowRight" },
      { text: "0\\nIns", keycode: "Numpad0" },
      { text: ".\\nDel", keycode: "NumpadDecimal" },
    ],
  ];

  // 新增input输入框逻辑
  const handleKeyInput = (index, event) => {
    const value = event.target.value;
    handleKeyDownUp(index, value);
  };

  const handleKeyMapDown = (index, event) => {
    event.preventDefault();
    const keycode = event.code;
    const key = keyMap[0].find((k) => k.keycode === keycode);
    if (key) {
      handleKeyDownUp(index, key.text);
    }
  };

  const handleKeyMapUp = (index, event) => {
    event.preventDefault();
    const keycode = event.code;
    const key = keyMap[0].find((k) => k.keycode === keycode);
    if (key) {
      handleKeyDownUp(index, key.text);
    }
  };

  const handleKeyDownUp = (index, val) => {
    console.log("asdasda");
    if (type === "dsk") {
      setOriginalDSKKey((prevState) => {
        const newState = [...prevState];
        newState[index] = val;
        return newState;
      });
      setSelectedDSKKey((prevState) => {
        const newState = [...prevState];
        let newItemText = val;
        if (newItemText.includes("\\n")) {
          newState[index] = newItemText.replace(/\\n/g, "");
        } else {
          newState[index] = newItemText;
        }
        return newState;
      });
    } else if (type === "end") {
      setOriginalEndKey(val);
      let newItemText = val;
      if (newItemText.includes("\\n")) {
        newItemText = newItemText.replace(/\\n/g, "");
        setSelectedKey2(newItemText);
      } else {
        setSelectedKey2(newItemText);
      }
    } else if (type === "tgl") {
      setOriginalTglSelectedKey(val);
      let newItemText = val;
      if (newItemText.includes("\\n")) {
        newItemText = newItemText.replace(/\\n/g, "");
        setTglSelectedKey(newItemText);
      } else {
        setTglSelectedKey(newItemText);
      }
    } else if (type === "mt") {
      if (index === 1) {
        setOriginalMtSelectedKey1(val);
        let newItemText = val;
        if (newItemText.includes("\\n")) {
          newItemText = newItemText.replace(/\\n/g, "");
          setMtSelectedKey1(newItemText);
        } else {
          setMtSelectedKey1(newItemText);
        }
      } else if (index === 2) {
        setOriginalMtSelectedKey2(val);
        let newItemText2 = val;
        if (newItemText2.includes("\\n")) {
          newItemText2 = newItemText2.replace(/\\n/g, "");
          setMtSelectedKey2(newItemText2);
        } else {
          setMtSelectedKey2(newItemText2);
        }
      }
    } else if (type === "mpt") {
      setOriginalMptKey((prevState) => {
        const newState = [...prevState];
        newState[index] = val;
        return newState;
      });
      setSelectedMptKey((prevState) => {
        const newState = [...prevState];
        let newItemText = val;
        if (newItemText.includes("\\n")) {
          newState[index] = newItemText.replace(/\\n/g, "");
        } else {
          newState[index] = newItemText;
        }
        return newState;
      });
    }
  };

  return (
    <>
      <div
        className="modal-overlay"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="modal-content"
          style={{
            position: "fixed",
            backgroundColor: isLight ? "white" : "#252525",
            color: isLight ? "black" : "white",
            padding: "10px",
            borderRadius: "10px",
            // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            zIndex: 1000,
            width: "75vw",
            height: type !== "dsk" ? "75vh" : "81vh",
            top: "12%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {/* 头部 */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {type === "dsk" && <span>{t("dynamic_key_travel")}</span>}
                {type === "mt" && <span>{t("hold_click")}</span>}
                {type === "tgl" && <span>{t("toggle_switch")}</span>}
                {type === "mpt" && <span>{t("mpt_key")}</span>}
                {type === "end" && <span>{t("end_key")}</span>}
                <img
                  src={headImg}
                  alt="帮助"
                  style={{
                    width: "16px", // 控制图片大小
                    height: "16px",
                    cursor: "pointer",
                  }}
                />
              </div>

              <p
                style={{
                  fontSize: "12px",
                  color: "#525252",
                  lineHeight: "1.5",
                  marginTop: "5px",
                }}
              >
                {type === "dsk" && <span>{t("bind_function")}</span>}
                {type === "mt" && <span>{t("click_longPress")}</span>}
                {type === "tgl" && <span>{t("bind_toggle_switch")}</span>}
                {type === "mpt" && <span>{t("different_depths")}</span>}
                {type === "end" && <span>{t("send_another_key")}</span>}
              </p>
            </div>

            {/* 按钮区域 */}
            <div
              style={{
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              {/* 删除按钮 */}
              {/* <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "18px",
                  color: "#666",
                }}
                onClick={onClose}
              >
                <img
                  src={deleteImg}
                  alt="删除"
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
              </button> */}

              {/* 取消按钮 */}
              <button
                style={{
                  backgroundColor: isLight ? "#E0E0E0" : "#393939",
                  border: "none",
                  padding: "6px 16px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginTop: "-5px",
                  color: isLight ? "black" : "white",
                }}
                onClick={() => {
                  setFocusedInput(null);
                  setFocusedTGLInput(null);
                  setFocusedMTInput(null);
                  setFocusedDSKInput(null);
                  setFocusedMptInput(null);
                  dispatch(userClickMapPendingKeyAction([]));
                  onClose();
                  if (!editingData) {
                    let superKeys = common.superkeys;
                    console.log("superKeys", superKeys);
                    let lastSuperKeys;
                    let lastDeletedKeyboard;
                    let hasSelectedValue = false;

                    // 检查是否有选择值
                    if (type === "end") {
                      hasSelectedValue = selectedKey2 !== "";
                      lastDeletedKeyboard = endNeedSuperKey;
                      lastSuperKeys =
                        superKeys.endKeys &&
                        superKeys.endKeys.filter(
                          (_, i) => _.keyboard[0] !== endNeedSuperKey[0]
                        );
                    } else if (type === "tgl") {
                      hasSelectedValue = tglSelectedKey !== "";
                      lastDeletedKeyboard = endNeedSuperKey;
                      lastSuperKeys =
                        superKeys &&
                        superKeys.tglKeys.filter(
                          (_, i) => _.keyboard[0] !== endNeedSuperKey[0]
                        );
                    } else if (type === "mt") {
                      hasSelectedValue =
                        mtSelectedKey1 !== "" || mtSelectedKey2 !== "";
                      lastDeletedKeyboard = endNeedSuperKey;
                      lastSuperKeys =
                        superKeys.mtKeys &&
                        superKeys.mtKeys.filter(
                          (_, i) => _.keyboard[0] !== endNeedSuperKey[0]
                        );
                    } else if (type === "mpt") {
                      hasSelectedValue = selectedMptKey.some(
                        (key) => key !== ""
                      );
                      lastDeletedKeyboard = endNeedSuperKey;
                      lastSuperKeys =
                        superKeys.mptKeys &&
                        superKeys.mptKeys.filter(
                          (_, i) => _.keyboard[0] !== endNeedSuperKey[0]
                        );
                    } else if (type === "dsk") {
                      hasSelectedValue = selectedDSKKey.some(
                        (key) => key !== ""
                      );
                      lastDeletedKeyboard = endNeedSuperKey;
                      lastSuperKeys =
                        superKeys.dskKeys &&
                        superKeys.dskKeys.filter(
                          (_, i) => _.keyboard[0] !== endNeedSuperKey[0]
                        );
                    }
                    console.log("lastSuperKeys", lastSuperKeys);
                    if (lastSuperKeys !== undefined && hasSelectedValue) {
                      dispatch({
                        type: "UPDATE_SUPERKEYS",
                        payload: {
                          type,
                          keys: lastSuperKeys,
                          deletedKeyboard: lastDeletedKeyboard,
                        },
                      });
                    }
                  }
                }}
              >
                {t("cancel")}
              </button>

              {/* 继续按钮 */}
              <button
                style={{
                  backgroundColor: "#4369ff",
                  color: "white",
                  border: "none",
                  padding: "6px 16px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginTop: "-5px",
                }}
                onClick={() => {
                  if (type === "end") {
                    if (selectedKey2) {
                      const updatedSelectedKeys = {
                        keyboard: editingData?.keyboard || endNeedSuperKey,
                        key1: editingData?.keyboard || endNeedSuperKey,
                        key2: selectedKey2,
                        originalKey: originalEndKey,
                        type: "end",
                      };
                      // dispatch(
                      //   updateDSKModalData(
                      //     type,
                      //     updatedSelectedKeys,
                      //     updatedSelectedKeys.keyboard
                      //   )
                      // );
                      onClose(updatedSelectedKeys);
                    }
                  } else if (type === "tgl") {
                    if (tglSelectedKey) {
                      const updatedSelectedKeys = {
                        keyboard: editingData?.keyboard || endNeedSuperKey,
                        tglkey: tglSelectedKey,
                        originalTglkey: originalTglSelectedKey,
                        type: "tgl",
                      };
                      // dispatch(
                      //   updateDSKModalData(
                      //     type,
                      //     updatedSelectedKeys,
                      //     updatedSelectedKeys.keyboard
                      //   )
                      // );
                      onClose(updatedSelectedKeys);
                    }
                  } else if (type === "mt") {
                    if (mtSelectedKey1 && mtSelectedKey2) {
                      const updatedSelectedKeys = {
                        keyboard: editingData?.keyboard || endNeedSuperKey,
                        mtkey1: mtSelectedKey1,
                        mtkey2: mtSelectedKey2,
                        sliderValue: sliderValue,
                        originalMtkey1: originalMtSelectedKey1,
                        originalMtkey2: originalMtSelectedKey2,
                        type: "mt",
                      };
                      // dispatch(
                      //   updateDSKModalData(
                      //     type,
                      //     updatedSelectedKeys,
                      //     updatedSelectedKeys.keyboard
                      //   )
                      // );
                      onClose(updatedSelectedKeys);
                    }
                  } else if (type === "mpt") {
                    if (
                      selectedMptKey[0] &&
                      selectedMptKey[1] &&
                      selectedMptKey[2]
                    ) {
                      const updatedSelectedKeys = {
                        keyboard: editingData?.keyboard || endNeedSuperKey,
                        mptkey1: selectedMptKey[0],
                        mptkey2: selectedMptKey[1],
                        mptkey3: selectedMptKey[2],
                        originalMptkey1: originalMptKey[0],
                        originalMptkey2: originalMptKey[1],
                        originalMptkey3: originalMptKey[2],
                        mptDis1: mptDis[0],
                        mptDis2: mptDis[1],
                        mptDis3: mptDis[2],
                        type: "mpt",
                      };
                      // dispatch(
                      //   updateDSKModalData(
                      //     type,
                      //     updatedSelectedKeys,
                      //     updatedSelectedKeys.keyboard
                      //   )
                      // );
                      onClose(updatedSelectedKeys);
                    }
                  } else if (type === "dsk") {
                    console.log("grid", gridValues);
                    const rowBinary = {};
                    for (const key in gridValues) {
                      const lastIndex = gridValues[key].length - 1;
                      if (gridValues[key][lastIndex] === "11") {
                        gridValues[key][lastIndex] = "10";
                      }
                      rowBinary[key] = gridValues[key].join("");
                    }
                    console.log("selectedDSKKeyrowBinary", rowBinary);
                    if (selectedDSKKey.length !== 0) {
                      const updatedSelectedKeys = {
                        keyboard: editingData?.keyboard || endNeedSuperKey,
                        dskkey1: selectedDSKKey[0],
                        dskkey2: selectedDSKKey[1],
                        dskkey3: selectedDSKKey[2],
                        dskkey4: selectedDSKKey[3],
                        originalDskkey1: originalDSKKey[0],
                        originalDskkey2: originalDSKKey[1],
                        originalDskkey3: originalDSKKey[2],
                        originalDskkey4: originalDSKKey[3],
                        dskDis1: selectedDSKDis[0].value,
                        dskDis2: selectedDSKDis[1].value,
                        dskDis3: selectedDSKDis[2].value,
                        dskDis4: selectedDSKDis[3].value,
                        rowStates1: rowBinary[0], // 添加行状态
                        rowStates2: rowBinary[1],
                        rowStates3: rowBinary[2],
                        rowStates4: rowBinary[3],
                        type: "dsk",
                      };
                      console.log(
                        "dispatchupdateDSKModalData",
                        updatedSelectedKeys
                      );
                      //   dispatch(updateDSKModalData(type, updatedSelectedKeys, updatedSelectedKeys.keyboard));
                      onClose(updatedSelectedKeys);
                    }
                  }
                }}
              >
                {t("continue")}
              </button>
            </div>
          </div>

          {/* 按钮和输入框  */}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {type === "dsk" && (
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "2vh",
                }}
              >
                {/* 顶部距离选择器 */}
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    marginLeft: "25vh",
                  }}
                >
                  {renderDSKDistances()}
                </div>

                {/* 4x4按钮网格 */}
                <div
                  style={{ display: "flex", alignItems: "center", gap: "50px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      position: "relative",
                      marginLeft: "28vh",
                    }}
                  >
                    {Array.from({ length: rows }).map((_, row) => (
                      <div
                        key={row}
                        style={{
                          display: "flex",
                          position: "relative",
                          gap: "28px",
                        }}
                      >
                        {Array.from({ length: cols }).map((_, col) => (
                          <div
                            key={col}
                            style={{
                              width: gridSize,
                              height: gridSize,
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => handleSelectClick(row, col)}
                            onDoubleClick={() => handlePathClick(row, col)}
                            onDragOver={(e) => handleDragOver(e, row, col)}
                            onDragEnd={handleDragEnd}
                            onDrop={(e) => {
                              const startCol = parseInt(
                                e.dataTransfer.getData("col"),
                                10
                              );
                              handleDrag(row, startCol, col);
                              setDraggingPath(null);
                            }}
                            onDragStart={(e) =>
                              e.dataTransfer.setData("col", col)
                            }
                            draggable={true} // **所有图片均可拖拽**
                          >
                            <img
                              src={
                                selectedButtons[row][col]
                                  ? selectPlusImg
                                  : plusImg
                              }
                              alt="plus"
                              style={{ width: imgSize, height: imgSize }}
                            />
                          </div>
                        ))}
                        {/* **渲染拖拽路径** */}
                        {dragPaths[row].map(([start, end], index) => (
                          <div
                            key={index}
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: `${start * (gridSize + 28)}px`,
                              width: `${(end - start) * (gridSize + 28)}px`,
                              height: `${pathHeight}px`,
                              backgroundColor: "#4368ff",
                              transform: "translateY(-50%)",
                              borderRadius: "18px",
                            }}
                            onClick={() => handlePathClick(row, start)}
                          />
                        ))}

                        {/* 渲染拖拽过程中的临时路径 */}
                        {draggingPath && draggingPath.row === row && (
                          <div
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: `${draggingPath.start * (gridSize + 28)}px`,
                              width: `${
                                (draggingPath.end - draggingPath.start) *
                                (gridSize + 28)
                              }px`,
                              height: `${pathHeight}px`,
                              backgroundColor: "#4368ff", // 半透明的预览路径
                              transform: "translateY(-50%)",
                              borderRadius: "18px",
                            }}
                          />
                        )}

                        {dragPaths[row].map(([start, end], index) => {
                          const targetX = end * (gridSize + 25) - gridSize / 2; // 目标图片前方位置
                          return (
                            <div
                              key={`img-${index}`}
                              style={{
                                position: "absolute",
                                top: "30%",
                                left: `${targetX}px`,
                                transform: "translateY(-50%)",
                                width: `${imgSize}px`,
                                height: `${imgSize}px`,
                              }}
                            >
                              <img
                                // src={dragEndImages[row][end] ? selectPlusImg : plusImg}
                                src={selectPlusImg}
                                alt="select-plus"
                                style={{ width: imgSize, height: imgSize }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  {/* 右侧空白框 */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      position: "relative",
                      marginTop: "-1vh",
                    }}
                  >
                    {Array.from({ length: 4 }).map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "5px",
                          textAlign: "center",
                          fontSize: "14px",
                          outline: "none",
                          border: isLight
                            ? "1px solid #ddd"
                            : "1px solid #2f2f2f",
                          backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                          color: isLight ? "black" : "white",
                        }}
                        value={selectedDSKKey[index]}
                        onChange={(e) => handleKeyInput(index, e)}
                        onKeyDown={(e) => handleKeyMapDown(index, e)}
                        onKeyUp={(e) => handleKeyMapUp(index, e)}
                        onFocus={() => handleDSKFocus(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}

            {type === "mt" && (
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  paddingLeft: "10vh",
                  marginTop: "2vh",
                }}
              >
                {/* 单击 & 长按 按钮 */}
                <div
                  style={{
                    display: "flex",
                    gap: "40px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#666",
                        marginBottom: "5px",
                      }}
                    >
                      {t("click")}
                    </span>
                    <input
                      type="text"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        textAlign: "center",
                        marginTop: "16px",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #2f2f2f",
                        backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                        color: isLight ? "black" : "white",
                      }}
                      value={mtSelectedKey1}
                      onChange={(e) => handleKeyInput(1, e)}
                      onKeyDown={(e) => handleKeyMapDown(1, e)}
                      onKeyUp={(e) => handleKeyMapUp(1, e)}
                      onFocus={() => {
                        handleMTFocus("mtKey1");
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#666",
                        marginBottom: "5px",
                      }}
                    >
                      {t("long_press")}
                    </span>
                    <input
                      type="text"
                      value={mtSelectedKey2}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        textAlign: "center",
                        marginTop: "16px",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #2f2f2f",
                        backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                        color: isLight ? "black" : "white",
                      }}
                      onChange={(e) => handleKeyInput(2, e)}
                      onKeyDown={(e) => handleKeyMapDown(2, e)}
                      onKeyUp={(e) => handleKeyMapUp(2, e)}
                      onFocus={() => {
                        handleMTFocus("mtKey2");
                      }}
                    />
                  </div>
                </div>

                {/* 滑动条 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: "14px", color: "#666" }}>
                    {t("hold_time")}
                  </span>
                  <input
                    type="range"
                    min="0"
                    max="200"
                    value={sliderValue}
                    // onChange={(e) => {
                    //   setSliderValue(e.target.value)
                    // }}
                    onChange={handleSliderChange} // 实时更新滑块值
                    onMouseUp={handleSliderChange} // 用户停止拖动时立即触发
                    style={{
                      // flexGrow: 1,
                      height: "3px",
                      width: "200px",
                      marginBottom: "1vh",
                      accentColor: "#4e6dd0", // 进度条颜色
                    }}
                  />
                  <span style={{ fontSize: "14px", color: "#666" }}>
                    {sliderValue}ms
                  </span>
                </div>

                {/* 说明文字 */}
                <div
                  style={{
                    fontSize: "12px",
                    color: "#666",
                    lineHeight: "1.5",
                    whiteSpace: "pre-line",
                    textAlign: "center",
                  }}
                >
                  <span>{t("whether_click")}</span>
                  <div>{t("enabling_rapid_trigger")}</div>
                </div>
              </div>
            )}

            {type === "tgl" && (
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  paddingLeft: "10vh",
                  marginTop: "10vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#666",
                      marginBottom: "5px",
                    }}
                  >
                    {t("key_one")}
                  </span>
                  <input
                    type="text"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      textAlign: "center",
                      marginTop: "16px",
                      border: isLight ? "1px solid #ddd" : "1px solid #2f2f2f",
                      backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                      color: isLight ? "black" : "white",
                    }}
                    value={tglSelectedKey}
                    onChange={(e) => handleKeyInput(3, e)}
                    onKeyDown={(e) => handleKeyMapDown(3, e)}
                    onKeyUp={(e) => handleKeyMapUp(3, e)}
                    onFocus={() => handleTGLFocus("tglKey")}
                  />
                </div>
              </div>
            )}

            {type === "mpt" && (
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  paddingLeft: "30vh",
                  marginTop: "8vh",
                }}
              >
                {mptDis.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #2f2f2f",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        src={downImg}
                        alt="arrow"
                        style={{
                          width: "10px",
                          height: "25px",
                          marginBottom: "5px",
                        }}
                      />
                    </div>

                    <input
                      type="text"
                      value={item.toFixed(2) + " mm"}
                      onChange={(e) =>
                        handleMptDistanceChange(index, e.target.value)
                      }
                      style={{
                        width: "60px",
                        textAlign: "center",
                        fontSize: "13px",
                        border: "none",
                        background: "transparent",
                        outline: "none",
                        color: isLight ? "black" : "white",
                        marginTop: "1vh",
                        borderBottom: isLight
                          ? "1px solid #2f2f2f"
                          : "1px solid #ddd",
                      }}
                      onClick={() => {
                        setSelectedMptIndex(index); // 记录点击的索引
                        setMptTravel(mptDis[index]); // 预填充当前值
                        setShowDisModal(true);
                      }}
                    />

                    {/* 输入框 */}
                    <input
                      type="text"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #2f2f2f",
                        backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                        fontSize: "14px",
                        textAlign: "center",
                        marginTop: "16px",
                        color: isLight ? "black" : "white",
                      }}
                      value={selectedMptKey[index]}
                      onChange={(e) => handleKeyInput(index, e)}
                      onKeyDown={(e) => handleKeyMapDown(index, e)}
                      onKeyUp={(e) => handleKeyMapUp(index, e)}
                      onFocus={() => handleMptFocus(index)}
                    />
                  </div>
                ))}
              </div>
            )}

            {type === "end" && (
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  paddingLeft: "20vh",
                  marginTop: "8vh",
                }}
              >
                {/* 单击 & 长按 按钮 */}
                <div
                  style={{
                    display: "flex",
                    gap: "40px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#666",
                        marginBottom: "5px",
                      }}
                    >
                      {t("key_one")}
                    </span>
                    <input
                      type="text"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        textAlign: "center",
                        marginTop: "16px",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #2f2f2f",
                        backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                        color: isLight ? "black" : "white",
                      }}
                      value={editingData?.keyboard || endNeedSuperKey}
                      disabled
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#666",
                        marginBottom: "5px",
                      }}
                    >
                      {t("key_two")}
                    </span>
                    <input
                      type="text"
                      value={selectedKey2}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        textAlign: "center",
                        marginTop: "16px",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #2f2f2f",
                        backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                        color: isLight ? "black" : "white",
                      }}
                      onChange={(e) => handleKeyInput(4, e)}
                      onKeyDown={(e) => handleKeyMapDown(4, e)}
                      onKeyUp={(e) => handleKeyMapUp(4, e)}
                      onFocus={() => handleFocus("key2")}
                    />
                  </div>
                </div>
              </div>
            )}

            {showDisModal && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 999,
                }}
              >
                <div
                  style={{
                    position: "fixed",
                    backgroundColor: isLight ? "white" : "#252525",
                    color: isLight ? "black" : "white",
                    borderRadius: "5px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    padding: "5px",
                    width: "32%",
                    height: "40%",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 1000,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "40px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    {type === "mpt" ? (
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {t("actuation_distance_settings")}
                      </p>
                    ) : (
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {t("dsk_actuation_distance_settings")}
                      </p>
                    )}

                    {/* 按钮组 */}
                    <div style={{ display: "flex", gap: "10px" }}>
                      <button
                        style={{
                          backgroundColor: isLight ? "#E0E0E0" : "#393939",
                          border: "none",
                          padding: "5px 10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          fontSize: "12px",
                          color: isLight ? "black" : "white",
                        }}
                        onClick={() => setShowDisModal(false)}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        style={{
                          background: "#4e6dd0",
                          border: "none",
                          padding: "5px 10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          fontSize: "12px",
                          color: "#fff",
                        }}
                        onClick={() => {
                          handleModalSave();
                        }}
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {/* 图片 */}
                    <img
                      src={imgUrl}
                      alt=""
                      style={{
                        width: "90px",
                        height: "90px",
                        marginRight: "3vh",
                      }}
                    />

                    {/* 竖向滑块 + 数值显示 */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="range"
                        min="0.10"
                        max={type === "mpt" ? "3.50" : "3.40"}
                        step="0.01"
                        defaultValue="1.50"
                        value={type === "mpt" ? mptTravel : dskTravel}
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value);
                          if (type === "mpt") {
                            setMptTravel(newValue);
                          } else if (type === "dsk") {
                            setDskTravel(newValue);
                          }
                        }}
                        style={{
                          writingMode: "vertical-lr", // 竖向滑块
                          width: "3px",
                          height: "100px",
                          marginBottom: "1vh",
                          accentColor: "#4e6dd0", // 进度条颜色
                        }}
                      />
                      {/* 显示当前数值 */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          marginTop: "0.5vh",
                          background: isLight ? "#f5f5f5" : "#2f2f2f",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          border: isLight
                            ? "1px solid #ddd"
                            : "1px solid #2f2f2f",
                        }}
                      >
                        <button
                          style={{
                            background: isLight ? "#ddd" : "#666666",
                            border: "none",
                            padding: "3px 6px",
                            marginRight: "5px",
                            cursor: "pointer",
                            borderRadius: "3px",
                            color: isLight ? "#525252" : "white",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            if (type === "mpt") {
                              setMptTravel(Math.max(0.1, mptTravel - 0.01));
                            } else if (type === "dsk") {
                              setDskTravel(Math.max(0.1, dskTravel - 0.01));
                            }
                          }}
                        >
                          -
                        </button>
                        <span>
                          {type === "mpt"
                            ? mptTravel.toFixed(2) + "mm"
                            : dskTravel.toFixed(2) + "mm"}
                        </span>
                        <button
                          style={{
                            background: isLight ? "#ddd" : "#666666",
                            border: "none",
                            padding: "3px 6px",
                            marginLeft: "5px",
                            cursor: "pointer",
                            borderRadius: "3px",
                            color: isLight ? "#525252" : "white",
                          }}
                          onClick={() => {
                            if (type === "mpt") {
                              setMptTravel(Math.min(3.5, mptTravel + 0.01));
                            } else if (type === "dsk") {
                              setDskTravel(Math.min(3.4, dskTravel + 0.01));
                            }
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div style={{ width: "30%", padding: "10px" }}>
              <p style={{ fontSize: "14px" }}>{t("test_your_bindings")}</p>

              <div style={{ marginTop: "10px" }}>
                <p style={{ fontSize: "12px", color: "#666" }}>
                  {t("pressed_key")}
                </p>
                <input
                  type="text"
                  value={pressedKeys}
                  style={{
                    width: "80%",
                    height: "35px",
                    border: isLight ? "1px solid #f2f4f5" : "1px solid #2f2f2f",
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                    color: isLight ? "black" : "white",
                  }}
                  readOnly
                  onFocus={() => setIsTestSectionFocused(true)}
                  onBlur={() => setIsTestSectionFocused(false)}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <p style={{ fontSize: "12px", color: "#666" }}>
                  {t("released_key")}
                </p>
                <input
                  type="text"
                  value={releasedKeys}
                  style={{
                    width: "80%",
                    height: "35px",
                    border: isLight ? "1px solid #f2f4f5" : "1px solid #2f2f2f",
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: isLight ? "#f2f4f5" : "#2f2f2f",
                    color: isLight ? "black" : "white",
                  }}
                  readOnly
                  onFocus={() => setIsTestSectionFocused(true)}
                  onBlur={() => setIsTestSectionFocused(false)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "30px",
              marginBottom: "2vh",
              justifyContent: "space-between",
              borderBottom: isLight ? "1px solid #ccc" : "1px solid #2f2f2f",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
              width: "fit-content",
              padding: "0 10px",
            }}
          >
            {keyMapData.map((line, lineNo) => (
              <div key={`line-${lineNo}`} style={{ display: "flex" }}>
                {line.map((item, index) => {
                  if (item.text) {
                    const next = nextWidthRef.current;
                    nextWidthRef.current = 1;
                    return (
                      <ButtonUnit
                        width={next}
                        height={1}
                        uid={item.uid}
                        key={`${lineNo}-${index}-${item.text}`}
                        text={item.text}
                        // text={"" + item.uid}
                        selected={selectedKeyUid === item.uid}
                        onClickEvent={(clicked) => {
                          console.log(
                            "We save key number is ",
                            lineNo * 100 + index
                          );
                          if (clicked) {
                            console.log("adfgsdf", item.text, item.uid);
                            dispatch(
                              userClickKeyboardBelowAction(item.text, item.uid)
                            );
                          }
                          if (type === "end") {
                            if (focusedInput === "key2") {
                              setOriginalEndKey(item.text);
                              let newItemText = item.text;
                              if (newItemText.includes("\\n")) {
                                newItemText = newItemText.replace(/\\n/g, "");
                                setSelectedKey2(newItemText);
                              } else {
                                setSelectedKey2(item.text);
                              }
                            }
                            setFocusedInput(null);
                          } else if (type === "tgl") {
                            if (focusedTGLInput === "tglKey") {
                              console.log("tglKey", item.text);
                              setOriginalTglSelectedKey(item.text);
                              let newItemText = item.text;
                              if (newItemText.includes("\\n")) {
                                newItemText = newItemText.replace(/\\n/g, "");
                                setTglSelectedKey(newItemText);
                              } else {
                                setTglSelectedKey(newItemText);
                              }
                            }
                            setFocusedTGLInput(null);
                          } else if (type === "mt") {
                            if (focusedMTInput === "mtKey1") {
                              console.log("mtKey1", item.text);
                              setOriginalMtSelectedKey1(item.text);
                              let newItemText = item.text;
                              if (item.text.includes("\\n")) {
                                newItemText = newItemText.replace(/\\n/g, "");
                                setMtSelectedKey1(newItemText);
                              } else {
                                setMtSelectedKey1(newItemText);
                              }
                            } else if (focusedMTInput === "mtKey2") {
                              setOriginalMtSelectedKey2(item.text);
                              let newItemText2 = item.text;
                              if (newItemText2.includes("\\n")) {
                                newItemText2 = newItemText2.replace(/\\n/g, "");
                                setMtSelectedKey2(newItemText2);
                              } else {
                                setMtSelectedKey2(item.text);
                              }
                            }
                          } else if (type === "mpt") {
                            if (focusedMptInput === 0) {
                              setOriginalMptKey((prevState) => {
                                const newState = [...prevState];
                                newState[0] = item.text;
                                return newState;
                              });
                              setSelectedMptKey((prevState) => {
                                const newState = [...prevState];
                                let newItemText = item.text;
                                if (newItemText.includes("\\n")) {
                                  newState[0] = newItemText.replace(/\\n/g, "");
                                } else {
                                  newState[0] = newItemText;
                                }
                                return newState;
                              });
                            } else if (focusedMptInput === 1) {
                              setOriginalMptKey((prevState) => {
                                const newState = [...prevState];
                                newState[1] = item.text;
                                return newState;
                              });
                              setSelectedMptKey((prevState) => {
                                const newState = [...prevState];
                                let newItemText = item.text;
                                if (newItemText.includes("\\n")) {
                                  newState[1] = newItemText.replace(/\\n/g, "");
                                } else {
                                  newState[1] = newItemText;
                                }
                                return newState;
                              });
                            } else if (focusedMptInput === 2) {
                              setOriginalMptKey((prevState) => {
                                const newState = [...prevState];
                                newState[2] = item.text;
                                return newState;
                              });
                              setSelectedMptKey((prevState) => {
                                const newState = [...prevState];
                                let newItemText = item.text;
                                if (newItemText.includes("\\n")) {
                                  newState[2] = newItemText.replace(/\\n/g, "");
                                } else {
                                  newState[2] = newItemText;
                                }
                                return newState;
                              });
                            }
                          } else if (type === "dsk") {
                            if (focusedDSKInput === 0) {
                              setOriginalDSKKey((prevState) => {
                                const newState = [...prevState];
                                newState[0] = item.text;
                                return newState;
                              });
                              setSelectedDSKKey((prevState) => {
                                const newState = [...prevState];
                                let newItemText = item.text;
                                if (newItemText.includes("\\n")) {
                                  newState[0] = newItemText.replace(/\\n/g, "");
                                } else {
                                  newState[0] = newItemText;
                                }
                                return newState;
                              });
                            } else if (focusedDSKInput === 1) {
                              setOriginalDSKKey((prevState) => {
                                const newState = [...prevState];
                                newState[1] = item.text;
                                return newState;
                              });
                              setSelectedDSKKey((prevState) => {
                                const newState = [...prevState];
                                let newItemText = item.text;
                                if (newItemText.includes("\\n")) {
                                  newState[1] = newItemText.replace(/\\n/g, "");
                                } else {
                                  newState[1] = newItemText;
                                }
                                return newState;
                              });
                            } else if (focusedDSKInput === 2) {
                              setOriginalDSKKey((prevState) => {
                                const newState = [...prevState];
                                newState[2] = item.text;
                                return newState;
                              });
                              setSelectedDSKKey((prevState) => {
                                const newState = [...prevState];
                                let newItemText = item.text;
                                if (newItemText.includes("\\n")) {
                                  newState[2] = newItemText.replace(/\\n/g, "");
                                } else {
                                  newState[2] = newItemText;
                                }
                                return newState;
                              });
                            } else if (focusedDSKInput === 3) {
                              setOriginalDSKKey((prevState) => {
                                const newState = [...prevState];
                                newState[3] = item.text;
                                return newState;
                              });
                              setSelectedDSKKey((prevState) => {
                                const newState = [...prevState];
                                let newItemText = item.text;
                                if (newItemText.includes("\\n")) {
                                  newState[3] = newItemText.replace(/\\n/g, "");
                                } else {
                                  newState[3] = newItemText;
                                }
                                return newState;
                              });
                            }
                          }
                        }}
                      />
                    );
                  } else {
                    if (item.x) {
                      return (
                        <ButtonUnit
                          width={item.x}
                          height={1}
                          key={`${lineNo}-${index}-${item.x}`}
                          text=""
                          selected={false}
                          onClickEvent={() => {}}
                        />
                      );
                    }

                    if (item.w) {
                      nextWidthRef.current = item.w;
                    } else {
                      nextWidthRef.current = 1;
                    }

                    return <div key={`empty-${lineNo}-${index}`}></div>;
                  }
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DSK;
