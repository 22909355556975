import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

function IndexEntry() {
  return (
    <div className="app">
      <Sidebar />

      <Outlet />
    </div>
  );
}

export default IndexEntry;
