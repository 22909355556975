import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabNavigation from "./components/TabNavigation";
import DKSIcon from './icons/dks@1x.png';
import MTIcon from './icons/mt@1x.png';
import SnappyTappyIcon from './icons/socd@1x.png';
import TGLIcon from './icons/tgl@1x.png';
import MPTIcon from './icons/mpt@1x.png';
import ENDIcon from './icons/end@1x.png';
import DSKModal from "./components/DSKModal.js";
import SOCDModal from './components/SOCDModal.js'
import deleteImg from './icons/删除@2x.png';
import { updateDSKModalData } from "./webhid/action";
import { userClickMapPendingKeyAction } from "./webhid/action";
import { useTranslation } from "react-i18next";

function SuperKeysPage({ }) {
  const [t, i18n] = useTranslation();
  const tabNavData = [t("advanced_settings"), t("dynamic_key_travel"), t("snappy_tappy"), t("hold_click"), t("toggle_switch"), t("mpt"), t("end")];
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const handleTabChange = (index) => {
    // 清空已选择的按键
    dispatch(userClickMapPendingKeyAction([]));
    setActiveTab(index);
  };

  const onActiveTabChange = (index) => {
    console.log('index', index)
    if (index === 0) {
      setActiveTab(1);
    } else if (index === 1) {
      setActiveTab(2);
    } else if (index === 2) {
      setActiveTab(3);
    } else if (index === 3) {
      setActiveTab(4);
    } else if (index === 4) {
      setActiveTab(5);
    } else if (index === 5) {
      setActiveTab(6);
    }
  }
 
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: "white",
        borderRadius: "8px",
        backgroundColor: isLight ? "white" : "#252525",
        color: isLight ? "black" : "white",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      {/* <h1>SuperKeysPage</h1>
      <p>SuperKeysPage</p> */}
      <TabNavigation
        data={tabNavData}
        onClickFunc={handleTabChange}
        activeTab={activeTab}
      />
      {activeTab === 0 && <AdvancedSettings type="advancedSettings" handleButtonClick={(index) => onActiveTabChange(index)} />}
      {activeTab === 1 && <SuperKey type="dsk" />}
      {activeTab === 2 && <SuperKey type="socd" />}
      {activeTab === 3 && <SuperKey type="mt" />}
      {activeTab === 4 && <SuperKey type="tgl" />}
      {activeTab === 5 && <SuperKey type="mpt" />}
      {activeTab === 6 && <SuperKey type="end" />}
    </div>
  );
}

function AdvancedSettings({ type, handleButtonClick  }) {
  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;

  const buttonData = [
    {
      icon: DKSIcon,
      title: t("dynamic_key_travel"),
      description: t("dynamic_key_travel_desc"),
    },
    {
      icon: SnappyTappyIcon,
      title: t("snappy_tappy"),
      description: t("snappy_tappy_desc"),
    },
    {
      icon: MTIcon,
      title:  t("dual_effect_click"),
      description: t("dual_effect_click_desc"),
    }, 
    {
      icon: TGLIcon,
      title:t("toggle_switch"),
      description: t("toggle_switch_desc"),
    },
    {
      icon: MPTIcon,
      title: t("mpt"),
      description: t("mpt_desc"),
    },
    {
      icon: ENDIcon,
      title: t("end"),
      description: t("end_desc"),
    },
  ];

  return (
    <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          height: "100%",
          margin: "0.2vh",
          backgroundColor: isLight ? "white" : "#252525",
          color: isLight ? "black" : "white",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            paddingRight: "3vh",
          }}
        >
          <span style={{ marginTop: "1vh", marginLeft: "1vh", fontWeight: "bold", fontSize: "15px" }}>{t("advanced_key")}</span>
        </div>
        <span
          style={{
            marginLeft: "1vh",
            marginTop: "1vh",
            fontSize: "13px",
            color: isLight ? "#525252" : "#8a8a8a",
          }}
        >
          {t("bind_advanced_key")}
        </span>
        <span
          style={{
            marginLeft: "1vh",
            fontSize: "13px",
            color: isLight ? "#525252" : "#8a8a8a",
          }}
        >
          {t("different_layers")}
        </span>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "1vh", justifyContent: "space-between" }}>
          {buttonData.map((button, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                width: "46%",
                padding: "0.5vh",
                marginBottom: "1vh",
                border: isLight ? '1px solid #f2f4f6' : '1px solid #393939',
                borderRadius: '8px',
                backgroundColor: isLight ? "#f2f4f6" : "#2f2f2f",
                cursor: "pointer",
              }}
              onClick={() => handleButtonClick(index)} 
            >
              {/* 图标 */}
              <img src={button.icon} alt={button.title} style={{ width: "35px", height: "auto", marginRight: "1vh", marginLeft: "1vh" }} />
              {/* 文字部分 */}
              <div>
                <div style={{ fontSize: "14px", color: isLight ? "#333" : "#848484" }}>
                  {button.title}
                </div>
                <div style={{ fontSize: "12px", color: isLight ? "#525252" : "#909090", whiteSpace: "pre-wrap" }}>
                  {button.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

}

function SuperKey({ type }) {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  const device = common.currentConfigName;
  const deviceConfigures = common[device];
  console.log('deviceConfigures', deviceConfigures)

  const mapPendingKey = deviceConfigures.mapPendingKey
  console.log('mapPendingKey', mapPendingKey)
  const [showDSK, setShowDSK] = useState(false);
  const [firstKey, setFirstKey] = useState(null);
  const [secondKey, setSecondKey] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  let isLight = common.isLight;
  
  // 确保 superkeys 对象存在
  const superkeys = deviceConfigures.superkeys || {
    dskKeys: [],
    socdKeys: [],
    endKeys: [],
    tglKeys: [],
    mtKeys: [],
    mptKeys: []
  };
  
  // 从 redux store 中读取保存的按键信息，并确保有默认值
  const savedSocdKeys = superkeys.socdKeys || [];
  const savedEndKeys = superkeys.endKeys || [];
  const savedTglKeys = superkeys.tglKeys || [];
  const savedMtKeys = superkeys.mtKeys || [];
  const savedMptKeys = superkeys.mptKeys || [];
  const savedDskKeys = superkeys.dskKeys || [];
  // 使用保存的数据初始化state
  const [keys, setKeys] = useState([]);
  const [dskKeys, setDskKeys] = useState(savedDskKeys);
  const [socdKeys, setSocdKeys] = useState(savedSocdKeys);
  const [endKeys, setEndKeys] = useState(savedEndKeys);
  const [tglKeys, setTglKeys] = useState(savedTglKeys);
  const [mtKeys, setMtKeys] = useState(savedMtKeys);
  const [mptKeys, setMptKeys] = useState(savedMptKeys);
  const [showAlert, setShowAlert] = useState(false);

  // 添加对 Redux store 中 superkeys 数据的监听
  useEffect(() => {
    const superkeys = deviceConfigures.superkeys || {
      dskKeys: [],
      socdKeys: [],
      endKeys: [],
      tglKeys: [],
      mtKeys: [],
      mptKeys: []
    };
    
    setDskKeys(superkeys.dskKeys || []);
    setSocdKeys(superkeys.socdKeys || []);
    setEndKeys(superkeys.endKeys || []);
    setTglKeys(superkeys.tglKeys || []);
    setMtKeys(superkeys.mtKeys || []);
    setMptKeys(superkeys.mptKeys || []);
  }, [deviceConfigures.superkeys]);

  // 删除按键组
  const handleDeleteKey = (index) => {
    let newKeys;
    let deletedKeyboard;  // 添加 deletedKeyboard 变量
    let deleteArr = [];

    if (type === 'end') {
      deletedKeyboard = endKeys[index].keyboard;  // 保存要删除的键盘值
      deleteArr = endKeys.filter((_, i) => i === index); 
      newKeys = endKeys.filter((_, i) => i !== index);      
      setEndKeys(newKeys);
    } else if (type === 'tgl') {
      deletedKeyboard = tglKeys[index].keyboard;
      deleteArr = tglKeys.filter((_, i) => i === index); 
      newKeys = tglKeys.filter((_, i) => i !== index);  
      console.log('newKeys', newKeys)
      setTglKeys(newKeys);
    } else if (type === 'mt') {
      deletedKeyboard = mtKeys[index].keyboard;
      deleteArr = mtKeys.filter((_, i) => i === index); 
      newKeys = mtKeys.filter((_, i) => i !== index);
      setMtKeys(newKeys);
      console.log('deletedKeyboard', mtKeys)
    } else if (type === 'mpt') {
      deletedKeyboard = mptKeys[index].keyboard;
      deleteArr = mptKeys.filter((_, i) => i === index); 
      newKeys = mptKeys.filter((_, i) => i !== index);
      setMptKeys(newKeys);
    } else if (type === 'socd') {
      console.log('asdasdasdasdasdasedas', socdKeys)
      deletedKeyboard = socdKeys[index].keyboard;
      deleteArr = socdKeys.filter((_, i) => i === index); 
      newKeys = socdKeys.filter((_, i) => i !== index);
      console.log('newKeys', newKeys)
      const targetKeys = [...socdKeys[index].keys].sort().join(","); // 目标 keys 排序后字符串化

      // 统计所有 keys 组合
      const keyMap = new Map();
      socdKeys.forEach((item, i) => {
        const sortedKeys = [...item.keys].sort().join(",");
        if (!keyMap.has(sortedKeys)) {
          keyMap.set(sortedKeys, []);
        }
        keyMap.get(sortedKeys).push(i);
      });

      // 获取需要删除的索引
      const deleteIndexes = keyMap.get(targetKeys) || [];

      // 过滤掉所有匹配项
      newKeys = socdKeys.filter((_, i) => !deleteIndexes.includes(i));

      setSocdKeys(newKeys);
    } else if (type === 'dsk') {
      deletedKeyboard = dskKeys[index].keyboard;
      deleteArr = dskKeys.filter((_, i) => i === index); 
      newKeys = dskKeys.filter((_, i) => i !== index);
      setDskKeys(newKeys);
    }
    console.log('asdasdasedfa', newKeys)

    // 通过 action 更新 redux store
    dispatch({
      type: "UPDATE_SUPERKEYS",
      payload: {
        type,
        keys: newKeys,
        deletedKeyboard,  // 添加被删除的键盘值
        deleteArr
      }
    });
  };


  useEffect(() => {
    console.log('Updated tglKeys:', tglKeys);
  }, [tglKeys]);


  useEffect(() => {
    if (type === 'socd' && mapPendingKey && mapPendingKey.length > 0) {
      // 如果还没有选择第一个按键
      if (!firstKey) {
        setFirstKey(mapPendingKey[0]);

      } 
      // 如果已经选择了第一个按键，且新选择的按键与第一个按键不同
      else if (!secondKey && mapPendingKey[0] !== firstKey) {
        setSecondKey(mapPendingKey[0]);

      }
    }
  }, [mapPendingKey, type, firstKey, secondKey]);

  useEffect(() => {
    if ((type === 'dsk' || type === 'end' || type === 'tgl' || type === 'mpt' || type === 'mt') && mapPendingKey && mapPendingKey.length > 0) {
      console.log('342423')
      if (!firstKey) {
        setFirstKey(mapPendingKey[0]);

      } 
    }
  }, [mapPendingKey, type, firstKey]);

  const handleDSKModalClose = (updatedSelectedKeys) => {
    if (!updatedSelectedKeys) return;

    const { type } = updatedSelectedKeys;

    if (type === 'end') {
      setEndKeys(prevEndKeys => {
        const newEndKeys = [...(prevEndKeys || [])];
        const existingIndex = newEndKeys.findIndex((item) =>
          item.keyboard[0] === updatedSelectedKeys.keyboard[0]
        );
      
        if (existingIndex !== -1) {
          return newEndKeys.map((item, index) =>
            index === existingIndex
              ? {
        keys: [updatedSelectedKeys.key1, updatedSelectedKeys.key2], 
                  originalKeys: [updatedSelectedKeys.key1, updatedSelectedKeys.originalKey],
        keyboard: updatedSelectedKeys.keyboard
                }
              : item
          );
      } else {
          return [
            ...newEndKeys,
            {
              keys: [updatedSelectedKeys.key1, updatedSelectedKeys.key2],
              originalKeys: [updatedSelectedKeys.key1, updatedSelectedKeys.originalKey],
              keyboard: updatedSelectedKeys.keyboard
            }
          ];
        }
      });
    } else if (type === 'tgl') {
      setTglKeys(prevTglKeys => {
        const newTglKeys = [...(prevTglKeys || [])];
        const existingIndex = newTglKeys.findIndex((item) =>
          item.keyboard[0] === updatedSelectedKeys.keyboard[0]
        );
      
        if (existingIndex !== -1) {
          return newTglKeys.map((item, index) =>
            index === existingIndex
              ? {
                  keys: [updatedSelectedKeys.tglkey], 
                  originalKeys: [updatedSelectedKeys.originalTglkey],
                  keyboard: updatedSelectedKeys.keyboard
                }
              : item
          );
      } else {
          return [
            ...newTglKeys,
            {
              keys: [updatedSelectedKeys.tglkey],
              originalKeys: [updatedSelectedKeys.originalTglkey],
              keyboard: updatedSelectedKeys.keyboard
            }
          ];
        }
      });
    } else if (type === 'mt') {
      setMtKeys(prevMtKeys => {
        const newMtKeys = [...(prevMtKeys || [])];
        const existingIndex = newMtKeys.findIndex((item) =>
          item.keyboard[0] === updatedSelectedKeys.keyboard[0]
        );

      if (existingIndex !== -1) {
          return newMtKeys.map((item, index) =>
            index === existingIndex
              ? {
                  keys: [updatedSelectedKeys.mtkey1, updatedSelectedKeys.mtkey2],
                  originalKeys: [updatedSelectedKeys.originalMtkey1, updatedSelectedKeys.originalMtkey2],
                  keyboard: updatedSelectedKeys.keyboard,
                  sliderValue: updatedSelectedKeys.sliderValue
                }
              : item
          );
      } else {
          return [
            ...newMtKeys,
            {
              keys: [updatedSelectedKeys.mtkey1, updatedSelectedKeys.mtkey2],
              originalKeys: [updatedSelectedKeys.originalMtkey1, updatedSelectedKeys.originalMtkey2],
              keyboard: updatedSelectedKeys.keyboard,
              sliderValue: updatedSelectedKeys.sliderValue
            }
          ];
        }
      });
    } else if (type === 'mpt') {
      setMptKeys(prevMptKeys => {
        const newMptKeys = [...(prevMptKeys || [])];
        const existingIndex = newMptKeys.findIndex((item) =>
          item.keyboard[0] === updatedSelectedKeys.keyboard[0]
        );
      
        if (existingIndex !== -1) {
          return newMptKeys.map((item, index) =>
            index === existingIndex
              ? {
                  keys: [updatedSelectedKeys.mptkey1, updatedSelectedKeys.mptkey2, updatedSelectedKeys.mptkey3],
                  originalKeys: [updatedSelectedKeys.originalMptkey1, updatedSelectedKeys.originalMptkey2, updatedSelectedKeys.originalMptkey3],
                  keyboard: updatedSelectedKeys.keyboard,
                  mptDis: [updatedSelectedKeys.mptDis1, updatedSelectedKeys.mptDis2, updatedSelectedKeys.mptDis3]
                }
              : item
          );
        } else {
          return [
            ...newMptKeys,
            {
              keys: [updatedSelectedKeys.mptkey1, updatedSelectedKeys.mptkey2, updatedSelectedKeys.mptkey3],
              originalKeys: [updatedSelectedKeys.originalMptkey1, updatedSelectedKeys.originalMptkey2, updatedSelectedKeys.originalMptkey3],
              keyboard: updatedSelectedKeys.keyboard,
              mptDis: [updatedSelectedKeys.mptDis1, updatedSelectedKeys.mptDis2, updatedSelectedKeys.mptDis3]
            }
          ];
        }
      });
    } else if (type === 'dsk') {
      setDskKeys(prevDskKeys => {
        const newDskKeys = [...(prevDskKeys || [])];
        const existingIndex = newDskKeys.findIndex((item) =>
          item.keyboard[0] === updatedSelectedKeys.keyboard[0]
        );
      
      if (existingIndex !== -1) {
          return newDskKeys.map((item, index) =>
            index === existingIndex
              ? {
                  keys: [updatedSelectedKeys.dskkey1, updatedSelectedKeys.dskkey2, updatedSelectedKeys.dskkey3, updatedSelectedKeys.dskkey4],
                  originalKeys: [updatedSelectedKeys.originalDskkey1, updatedSelectedKeys.originalDskkey2, updatedSelectedKeys.originalDskkey3, updatedSelectedKeys.originalDskkey4],
                  keyboard: updatedSelectedKeys.keyboard,
                  dskDis: [updatedSelectedKeys.dskDis1, updatedSelectedKeys.dskDis2, updatedSelectedKeys.dskDis3, updatedSelectedKeys.dskDis4],
                  rowBinary: [updatedSelectedKeys.rowStates1, updatedSelectedKeys.rowStates2, updatedSelectedKeys.rowStates3, updatedSelectedKeys.rowStates4]
                }
              : item
          );
      } else {
          return [
            ...newDskKeys,
            {
              keys: [updatedSelectedKeys.dskkey1, updatedSelectedKeys.dskkey2, updatedSelectedKeys.dskkey3, updatedSelectedKeys.dskkey4],
              originalKeys: [updatedSelectedKeys.originalDskkey1, updatedSelectedKeys.originalDskkey2, updatedSelectedKeys.originalDskkey3, updatedSelectedKeys.originalDskkey4],
              keyboard: updatedSelectedKeys.keyboard,
              dskDis: [updatedSelectedKeys.dskDis1, updatedSelectedKeys.dskDis2, updatedSelectedKeys.dskDis3, updatedSelectedKeys.dskDis4],
              rowBinary: [updatedSelectedKeys.rowStates1, updatedSelectedKeys.rowStates2, updatedSelectedKeys.rowStates3, updatedSelectedKeys.rowStates4]
            }
          ];
        }
      });
    } else if (type === 'socd') {
      // Create arrays of sorted keys for comparison
      const newKey1 = [secondKey, firstKey].sort().join(',');
      const newKey2 = [firstKey, secondKey].sort().join(',');

      console.log('newKey1', newKey1)
      console.log('newKey2', newKey2)
      
      // Create new array to store updated keys
      let newSocdKeys = [...socdKeys];
      
      // Find existing entries with matching keys
      const existingIndices = socdKeys.reduce((acc, item, index) => {
        const sortedKeys = [...item.keys].sort().join(',');
        if (sortedKeys === newKey1 || sortedKeys === newKey2) {
          acc.push(index);
        }
        return acc;
      }, []);

      // If matching entries found, update them
      if (existingIndices.length > 0) {
        // Remove existing entries
        newSocdKeys = newSocdKeys.filter((_, index) => !existingIndices.includes(index));
      }

      // Add new entries
      newSocdKeys.push(
        { 
          keys: [secondKey, firstKey], 
          keyboard: firstKey, 
          isFastTriggerEnabled: updatedSelectedKeys.isFastTriggerEnabled, 
          behaviorMode: updatedSelectedKeys.behaviorMode, 
          socdTravelValue: updatedSelectedKeys.socdTravelValue 
        },
        { 
          keys: [firstKey, secondKey], 
          keyboard: secondKey, 
          isFastTriggerEnabled: updatedSelectedKeys.isFastTriggerEnabled, 
          behaviorMode: updatedSelectedKeys.behaviorMode, 
          socdTravelValue: updatedSelectedKeys.socdTravelValue 
        }
      );

      setSocdKeys(newSocdKeys);
    }
  };

  const handleSelectButtonClick = (index) => {
    if (type === 'socd') {
      const selectedGroup = socdKeys[index];
      setFirstKey(selectedGroup.keys[0]);
      setSecondKey(selectedGroup.keys[1]);
      setEditingIndex(index);
      setShowDSK(true);
    } else if (type === 'dsk') {
      const selectedGroup = dskKeys[index];
      setFirstKey(selectedGroup.keyboard);
      setEditingIndex(index);
      setShowDSK(true);
    } else if (type === 'tgl') {
      const selectedGroup = tglKeys[index];
      setFirstKey(selectedGroup.keyboard);
      setEditingIndex(index);
      setShowDSK(true);
    } else if (type === 'mt') {
      const selectedGroup = mtKeys[index];
      setFirstKey(selectedGroup.keyboard);
      setEditingIndex(index);
      setShowDSK(true);
    } else if (type === 'mpt') {
      const selectedGroup = mptKeys[index];
      setFirstKey(selectedGroup.keyboard);
      setEditingIndex(index);
      setShowDSK(true);
    } else if (type === 'end') {
      const selectedGroup = endKeys[index];
      setFirstKey(selectedGroup.keyboard);
      setEditingIndex(index);
      setShowDSK(true);
    }
  }


  return (
    <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "flex-start", justifyContent: "flex-start", marginTop: "1vh" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap", // 每行最多 4 组，自动换行
          gap: "1vh",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {/* 渲染按键组 */}
        {(type === 'end' ? endKeys : (type === 'tgl' ? tglKeys : (type === 'mt' ? mtKeys : (type === 'mpt' ? mptKeys : (type === 'socd' ? socdKeys : dskKeys))))).length > 0 && (
          (type === 'end' ? endKeys : (type === 'tgl' ? tglKeys : (type === 'mt' ? mtKeys : (type === 'mpt' ? mptKeys : (type === 'socd' ? socdKeys : dskKeys))))).map((group, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                width: "23%", // 控制每个按键组大小，4 组一行
                height: "5vh",
                padding: "0.5vh",
                // border: "1px solid #f2f4f6",
                borderRadius: "5px",
                backgroundColor: isLight ? "#f2f4f6" : "#2f2f2f",
                justifyContent: "space-between",
                cursor: "pointer",
                border: isLight ? "1px solid #f2f4f6" : "1px solid #242424",
              }}          
            >
              {/* 数组的第一个值 */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "3vh",
                  height: "3vh",
                  borderRadius: "5px",
                  backgroundColor: "#e0e0e0",
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginLeft: "1vh",
                }}
                onClick={() => {
                  handleSelectButtonClick(index)
                }}
              >
                {/* {group.keys[0]} */}
                <span style={{ color: "black" }}>{group.keyboard}</span>
              </div>

              {/* 竖线 */}
              <div
                style={{
                  width: "1px",
                  height: "4vh",
                  backgroundColor: isLight ? "#dcdfe6" : "#393939",
                  marginLeft: "1vh",
                }}
              ></div>

              {/* DKS 图片 */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  console.log('select button click', index)
                  handleSelectButtonClick(index)
                }}
              >
                {/* <img src={DKSIcon} alt="DKS" style={{ width: "80%", height: "80%" }} /> */}
                <img
                  src={type === "end" ? ENDIcon : (type === 'tgl' ? TGLIcon : (type === 'mt' ? MTIcon : (type === 'mpt' ? MPTIcon : (type === 'socd' ? SnappyTappyIcon : DKSIcon))))}
                  alt={type === "end" ? "END" : (type === 'tgl' ? "TGL" : (type === 'mt' ? "MT" : (type === 'mpt' ? "MPT" : (type === 'socd' ? "SOCD" : "DKS"))))}
                  style={{ width: "80%", height: "80%" }}
                />
              </div>

              <div
                style={{
                  width: "12vh"
                }}
                onClick={() => {
                  console.log('select button click', index)
                  handleSelectButtonClick(index)
                }}    
              >

                {/* 4 个输入框 */}
                {group.keys.map((key, keyIndex) => (
                  <input
                    key={keyIndex}
                    type="text"
                    value={key}
                    disabled
                    style={{
                      width: "2vh",
                      height: "2.3vh",
                      textAlign: "center",
                      border: isLight ? "1px solid #dcdfe6" : "1px solid #4d4d4d",
                      borderRadius: "5px",
                      backgroundColor: isLight ? "#fff" : "#2f2f2f",
                      marginLeft: "0.5vh",
                      fontSize: "12px",
                      color: isLight ? "black" : "white",
                    }}
                  />
                ))}
              </div>


              {/* 竖线 */}
              <div
                style={{
                  width: "1px",
                  height: "4vh",
                  backgroundColor: isLight ? "#dcdfe6" : "#393939",
                  marginLeft: "1vh",
                }}
              ></div>

              {/* 删除按钮 */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "5vh",
                  height: "5vh",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteKey(index)}
              >
                <img
                  src={deleteImg}
                  alt="删除"
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))
        )}

        {/* 添加按键按钮 */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "23%", // 保持与按键组一致
            height: "5vh",
            padding: "0.5vh",
            border: isLight ? "1px solid #f2f4f6" : "1px solid #242424",
            backgroundColor: isLight ? "#f2f4f6" : "#2f2f2f",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (mapPendingKey.length !== 0) {
              setShowDSK(true)
            } else {
              setShowAlert(true)
            }

          }}
        >
          <span style={{ textAlign: "center", fontSize: "12px", color: isLight ? "#5c5e60" : "white" }}>{t("add_key")}</span>
        </div>
      </div>

      {showAlert && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
        >
          <div
            className="modal-content"
            style={{
              position: "fixed",
              backgroundColor: isLight ? "white" : "#252525",
              color: isLight ? "black" : "white",
              padding: "5px",
              borderRadius: "5px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              zIndex: 1000,
              width: "18%",
              height: "22%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <img
              src="/warning.png"
              style={{
                border: "1px #276bF9 solid",
                borderRadius: "50%",
                padding: "6px",
                width: "4vh",
                height: "4vh",
              }}
            />
            <span style={{ fontSize: "0.8rem" }}>{t("tip")}</span>
            <span style={{ fontSize: "0.7rem" }}>{t("keyboard_bind")}</span>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  backgroundColor: "#276bF9",
                  borderRadius: "5px",
                  padding: "0.6vh 1.7vh",
                }}
                onClick={() => {
                  setShowAlert(false)
                }}
              >
                {t("confirm")}
              </div>
            </div>
          </div>
        </div>
      )}

      {showDSK && type !== "socd" &&
        <DSKModal
          type={type}
          editingIndex={editingIndex}
          isLight={isLight}
          editingData={type === 'dsk' ? dskKeys[editingIndex] : 
                      type === 'tgl' ? tglKeys[editingIndex] :
                      type === 'mt' ? mtKeys[editingIndex] :
                      type === 'mpt' ? mptKeys[editingIndex] :
                      type === 'end' ? endKeys[editingIndex] : null}
          onClose={(selectedKeys) => {
            setShowDSK(false);
            setEditingIndex(null);
            dispatch(userClickMapPendingKeyAction([]));
            if (selectedKeys) {
              handleDSKModalClose(selectedKeys);
            }
          }}
        />
      }
      {showDSK && type === "socd" && 
        <SOCDModal 
          firstKey={firstKey}
          secondKey={secondKey}
          editingIndex={editingIndex}
          isLight={isLight}
          editingData={type === 'socd' ? socdKeys[editingIndex] : null}
          onClose={(selectedKeys) => { 
            setShowDSK(false);          
            setFirstKey(null);
            setSecondKey(null);
            setEditingIndex(null);
            dispatch(userClickMapPendingKeyAction([]));
            if (selectedKeys) {
              handleDSKModalClose(selectedKeys);
            }
          }} 
        />
      }
    </div>
  );
}

export default SuperKeysPage;
