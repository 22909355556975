import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

function AlerDialog({ title, content, buttons }) {
  const [t, i18n] = useTranslation();

  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const dispatch = useDispatch();
  console.log("Alertdialog received buttons", buttons);
  return (
    <div
      style={{
        position: "absolute",
        width: "18%",
        height: "22%",
        top: "50%",
        left: "50%",
        backgroundColor: isLight ? "white" : "#2B2B2B",
        borderRadius: "6px",
        zIndex: 999,
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <img
        src="/warning.png"
        style={{
          border: "1px #276bF9 solid",
          borderRadius: "50%",
          padding: "6px",
          width: "4vh",
          height: "4vh",
        }}
      />
      <span style={{ fontSize: "0.8rem" }}>{title}</span>
      <span style={{ fontSize: "0.7rem" }}>{content}</span>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {buttons.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: item[1],
              borderRadius: "5px",
              padding: "0.6vh 1.7vh",
              fontSize: "0.8rem",
            }}
            onClick={() => {
              item[2]();
            }}
          >
            {item[0]}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AlerDialog;
