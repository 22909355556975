// crl 01, shift 02, alt 04, win 08,
const gameDisable = [
  ["WIN", "00 e3"],
  ["ALT+A", "04 04"],
  ["ALT+TAB", "04 2b"],
  ["ALT+F4", "04 3d"],
  ["CTRL+ESC", "01 29"],
  ["CTRL+ALT+DELETE", "05 4C"],
  ["CTRL+W", "01 1a"],
  ["WIN+SHIFT+S", "0a 16"],
];

function saveBits(orignalData, index, checked) {
  let mask = checked ? 1 << index : ~(1 << index);

  if (checked) {
    orignalData |= mask;
  } else {
    orignalData &= mask;
  }

  return orignalData;
}

// const allPossibleKey = {
//   WIN: "00 e3|00 e7", // Left
//   ALT: "00 e2|00 e6",
//   A: "00 04",
//   TAB: "00 2b",
//   F4: "00 3d",
//   CTRL: "00 e0|00 e4",
//   ESC: "00 29",
//   DELETE: "00 4c",
//   W: "00 1a",
//   SHIFT: "00 e1|00 e5",
//   S: "00 16",
// };

export { gameDisable, saveBits };
