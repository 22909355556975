// 在组件中使用
import { useDispatch, useSelector } from "react-redux";
import { connectDeviceAction, userSaveProductList } from "./webhid/action";
import { useTranslation } from "react-i18next";

function DeviceListPage() {
  const [t, i18n] = useTranslation();

  const common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const dispatch = useDispatch();

  const {
    productCategoryData = [],
    categoryDataisLoading = false,
    categoryDataLoadingError = null,
  } = useSelector((state) => state.persistent.productCategoryListData || {});

  return (
    <div
      className="device-container"
      style={{
        display: "flex",
        position: "relative",
        //border: "1px red solid",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        backgroundColor: isLight ? "#f2f4f5" : "#212023",
        color: isLight ? "black" : "gray",
        width: "100%",
        padding: "3vh 4vh",
        overflow: "auto",
        flexWrap: "wrap",
        rowGap: "1vh",
        gap: "1vh",
        margin: 0,
        flex: 1,
      }}
      onClick={() => {}}
    >
      {common.deviceList.map((item, index) => (
        <DeviceBlock
          key={index}
          icon={item.picture}
          text={item.type}
          onClick={() => {
            console.log("We are connect.....");
            dispatch(connectDeviceAction([item.vid], [item.pid]));
          }}
        />
      ))}

      {!productCategoryData && categoryDataisLoading && (
        <div>{t("category_loading_hints")}</div>
      )}
      {!productCategoryData && categoryDataLoadingError && (
        <div>{t("category_loading_error")}</div>
      )}
      {productCategoryData && (
        <NewBlock
          icon="/plus.png"
          text={t("device_add_new")}
          onClick={(e) => {
            dispatch(userSaveProductList(productCategoryData));

            let venders = productCategoryData.map((item, index) => {
              return item.vid;
            });

            let products = productCategoryData.map((item, index) => {
              return item.pid;
            });

            console.log("venders", venders, "products", products);
            dispatch(connectDeviceAction(venders, products));
          }}
        />
      )}
    </div>
  );
}

export default DeviceListPage;

function DeviceBlock({ icon, text, onClick }) {
  const common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  return (
    <div
      style={{
        backgroundColor: isLight ? "white" : "#333333",
        borderRadius: "5px",
        width: "15vw",
        height: "9vw",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "3vh",
      }}
      onClick={() => {
        onClick();
      }}
    >
      <img
        src={icon}
        style={{
          flex: 1,
          width: "60%",
          // height: "100%",
          marginBottom: "1vh",
          objectFit: "contain",
        }}
      ></img>
      <span> {text}</span>
    </div>
  );
}

function NewBlock({ icon, text, onClick }) {
  const common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;

  return (
    <div
      style={{
        backgroundColor: isLight ? "white" : "#333333",
        width: "15vw",
        borderRadius: "5px",
        height: "9vw",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "3vh",
      }}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <img src={icon} style={{ width: "12%", marginBottom: "2vh" }}></img>
      <span> {text}</span>
    </div>
  );
}
