import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; // 推荐使用 Redux Toolkit

// 创建异步 thunk
export const fetchServerProductData = createAsyncThunk(
  "server_product_data/fetchData",
  async () => {
    const response = await fetch("https://rockzhang.com/keyboard/devices.json");
    const data = await response.json();
    return data;
  }
);

const productCategoryDataSlice = createSlice({
  name: "server_product_data",
  initialState: {
    productCategoryData: null,
    categoryDataisLoading: false,
    categoryDataLoadingError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServerProductData.pending, (state) => {
        state.categoryDataisLoading = true;
        state.categoryDataLoadingError = null;
      })
      .addCase(fetchServerProductData.fulfilled, (state, action) => {
        state.productCategoryData = action.payload;
        state.categoryDataisLoading = false;
      })
      .addCase(fetchServerProductData.rejected, (state, action) => {
        state.categoryDataisLoading = false;
        state.categoryDataLoadingError = action.error.message;
      });
  },
});

export default productCategoryDataSlice.reducer;
