// This file has been deprecated, it was replace by fetching a network json.
// That will help our framework to adapt to various keyboard.
const stdWidth = 118;
const stdHeight = 114;

const line1Y = 80;
const line2Y = 248;
const line3Y = 398;
const line4Y = 548;
const line5Y = 699;
const line6Y = 850;

// 按键区域（每个键的坐标和大小）
const keys = [
  // line 1
  {
    id: "Esc",
    x: 60,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 9,
  },
  {
    id: "F1",
    x: 245,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 10,
  },
  {
    id: "F2",
    x: 392,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 3,
  },
  {
    id: "F3",
    x: 543,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 9,
  },
  {
    id: "F4",
    x: 693,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 10,
  },
  {
    id: "F5",
    x: 874,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 4,
  },
  {
    id: "F6",
    x: 1024,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 1,
  },
  {
    id: "F7",
    x: 1172,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 9,
  },
  {
    id: "F8",
    x: 1321,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 3,
  },
  {
    id: "F9",
    x: 1509,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 1,
  },
  {
    id: "F10",
    x: 1658,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 7,
  },
  {
    id: "F11",
    x: 1809,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 8,
  },
  {
    id: "F12",
    x: 1960,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 9,
  },
  {
    id: "LOCK",
    x: 2140,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 3,
  },
  {
    id: "Prtscr",
    x: 2348,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 1,
  },
  {
    id: "ScrLk",
    x: 2497,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 9,
  },
  {
    id: "Prtblk",
    x: 2650,
    y: line1Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 3,
  },

  // line2
  {
    id: "~",
    x: 60,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 0,
  },
  {
    id: "!1",
    x: 216,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 8,
  },
  {
    id: "@2",
    x: 362,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 1,
  },
  {
    id: "#3",
    x: 513,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 8,
  },
  {
    id: "$4",
    x: 663,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 1,
  },
  {
    id: "%5",
    x: 812,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 3,
  },
  {
    id: "^6",
    x: 962,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 0,
  },
  {
    id: "&7",
    x: 1112,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 8,
  },
  {
    id: "*8",
    x: 1259,
    y: line2Y,
    width: stdWidth + 2,
    height: stdHeight,
    row: 2,
    col: 10,
  },
  {
    id: "(9",
    x: 1413,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 0,
  },
  {
    id: ")0",
    x: 1562,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 6,
  },
  {
    id: "_-",
    x: 1710,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 0,
  },
  {
    id: "+=",
    x: 1863,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 1,
  },
  {
    id: "Backspace",
    x: 2012,
    y: line2Y,
    width: 246,
    height: stdHeight,
    row: 3,
    col: 10,
  },
  {
    id: "Insert",
    x: 2348,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 7,
  },
  {
    id: "Home",
    x: 2498,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 2,
  },
  {
    id: "PgUp",
    x: 2650,
    y: line2Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 10,
  },

  // line3
  {
    id: "Tab",
    x: 60,
    y: line3Y,
    width: 192,
    height: stdHeight,
    row: 0,
    col: 7,
  },
  {
    id: "Q",
    x: 282,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 15,
  },
  {
    id: "W",
    x: 432,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 2,
  },
  {
    id: "E",
    x: 582,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 0,
  },
  {
    id: "R",
    x: 733,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 2,
  },
  {
    id: "T",
    x: 882,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 11,
  },
  {
    id: "Y",
    x: 1033,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 15,
  },
  {
    id: "U",
    x: 1182,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 2,
  },
  {
    id: "I",
    x: 1332,
    y: line3Y,
    width: stdWidth + 2,
    height: stdHeight,
    row: 2,
    col: 11,
  },
  {
    id: "O",
    x: 1482,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 5,
  },
  {
    id: "P",
    x: 1632,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 2,
  },
  {
    id: "{[",
    x: 1782,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 15,
  },
  {
    id: "}]",
    x: 1932,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 2,
  },
  {
    id: "|\\",
    x: 2082,
    y: line3Y,
    width: 176,
    height: stdHeight,
    row: 3,
    col: 11,
  },

  {
    id: "Del",
    x: 2348,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 0,
  },
  {
    id: "End",
    x: 2498,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 8,
  },
  {
    id: "PgDn",
    x: 2650,
    y: line3Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 4,
  },

  // line4
  {
    id: "Caps Lock",
    x: 60,
    y: line4Y,
    width: 262,
    height: stdHeight,
    row: 0,
    col: 6,
  },
  {
    id: "A",
    x: 357,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 14,
  },
  {
    id: "S",
    x: 506,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 11,
  },
  {
    id: "D",
    x: 655,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 7,
  },
  {
    id: "F",
    x: 804,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 12,
  },
  {
    id: "G",
    x: 953,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 7,
  },
  {
    id: "H",
    x: 1104,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 14,
  },
  {
    id: "J",
    x: 1251,
    y: line4Y,
    width: stdWidth + 2,
    height: stdHeight,
    row: 2,
    col: 12,
  },
  {
    id: "K",
    x: 1404,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 4,
  },
  {
    id: "L",
    x: 1555,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 10,
  },
  {
    id: ":;",
    x: 1705,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 8,
  },
  {
    id: "\"'",
    x: 1857,
    y: line4Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 7,
  },
  {
    id: "Enter",
    x: 2009,
    y: line4Y,
    width: 247,
    height: stdHeight,
    row: 3,
    col: 4,
  },

  // line5
  {
    id: "ShiftL",
    x: 60,
    y: line5Y,
    width: 336,
    height: stdHeight,
    row: 0,
    col: 13,
  },
  {
    id: "Z",
    x: 427,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 0,
    col: 4,
  },
  {
    id: "X",
    x: 576,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 15,
  },
  {
    id: "C",
    x: 725,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 6,
  },
  {
    id: "V",
    x: 874,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 1,
    col: 13,
  },
  {
    id: "B",
    x: 1025,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 6,
  },
  {
    id: "N",
    x: 1178,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 2,
    col: 13,
  },
  {
    id: "M",
    x: 1327,
    y: line5Y,
    width: stdWidth + 2,
    height: stdHeight,
    row: 2,
    col: 5,
  },
  {
    id: "<,",
    x: 1476,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 4,
  },
  {
    id: ">.",
    x: 1625,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 3,
  },
  {
    id: "?/",
    x: 1775,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 14,
  },
  {
    id: "ShiftR",
    x: 1927,
    y: line5Y,
    width: 330,
    height: stdHeight,
    row: 3,
    col: 5,
  },
  {
    id: "Arrowup",
    x: 2498,
    y: line5Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 5,
  },

  // line6
  {
    id: "CtrlL",
    x: 60,
    y: line6Y,
    width: 192,
    height: stdHeight,
    row: 0,
    col: 5,
  },
  {
    id: "WinL",
    x: 281,
    y: line6Y,
    width: 192,
    height: stdHeight,
    row: 0,
    col: 12,
  },
  {
    id: "AltL",
    x: 502,
    y: line6Y,
    width: 192,
    height: stdHeight,
    row: 1,
    col: 14,
  },
  {
    id: "Spacebar",
    x: 725,
    y: line6Y,
    width: 870,
    height: stdHeight,
    row: 1,
    col: 5,
  },
  {
    id: "AltR",
    x: 1625,
    y: line6Y,
    width: stdWidth,
    height: stdHeight,
    row: 5,
    col: 9,
  },
  {
    id: "WinR",
    x: 1777,
    y: line6Y,
    width: 188,
    height: stdHeight,
    row: 3,
    col: 6,
  },
  {
    id: "Fn",
    x: 1992,
    y: line6Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 13,
  },
  {
    id: "CtrlR",
    x: 2143,
    y: line6Y,
    width: stdWidth,
    height: stdHeight,
    row: 3,
    col: 12,
  },

  {
    id: "ArrowL",
    x: 2348,
    y: line6Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 6,
  },
  {
    id: "ArrowD",
    x: 2498,
    y: line6Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 12,
  },
  {
    id: "ArrowR",
    x: 2650,
    y: line6Y,
    width: stdWidth,
    height: stdHeight,
    row: 4,
    col: 11,
  },
];

//KeyBoardMap.js
function getMiddleKeysData() {
  return keys;
}

//commonReducer.js
function getMiddleKeyObjectById(id) {
  // 因为我们key值是[]数组，在多选的情况下会以","做join, 因此"<,"会被split.
  // 所以这里简单的打个补丁。
  if (id === "<") id = "<,";
  return keys.find((key) => key.id === id); // This will return the key object if found, or undefined if not found
}

export { getMiddleKeysData, getMiddleKeyObjectById };
