// src/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit"; // Updated import
import { persistentRootReducer } from "./rootReducer"; // Updated import

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // 默认使用 localStorage
import { thunk } from "redux-thunk"; // Change to named import
import connectReducer from "./connectReducer";

// 配置持久化
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, persistentRootReducer);

export const rootReducer = combineReducers({
  persistent: persistedReducer,
  connect: connectReducer,
});

const StateStore = configureStore({
  // Updated store creation
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializable check if necessary
    }).concat(thunk), // Add thunk middleware
});

const persistor = persistStore(StateStore);

export { StateStore, persistor };
