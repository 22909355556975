import TabNavigation from "./components/TabNavigation";
import StatusBar from "./StatusBar";
import "./LightnessPage.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Wheel from "@uiw/react-color-wheel";
import { hsvaToHex } from "@uiw/color-convert";
import {
  userClickLightnessAction,
  userMouseUpPosAction,
  userStopDragAction,
} from "./webhid/action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// 联系我们组件
function LightnessPage({}) {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.currentConfigName;
  common = common[device];

  const [hsva, setHsva] = useState({ h: 214, s: 43, v: 90, a: 1 });

  const hexColor = common.lightness.hexcolor;

  const colorProgress = common.lightness.progress;

  const colorBlockStyle = {
    margin: "1.5vh 0vh 0.3vh 0vh",
    width: "100%",
    background: `linear-gradient(90deg, #ddd 0%, ${hexColor} 100%)`,
  };

  const brightValue = common.lightness.brightness;
  const handleBrightChange = (value) => {
    dispatch(userClickLightnessAction({ bright: value }));
  };

  const speedValue = common.lightness.speed;

  const handleSpeedChange = (value) => {
    dispatch(userClickLightnessAction({ speed: value }));
  };

  const customColor = common.lightness.color; //"#153DDF",

  const red = parseInt(customColor.substring(1, 3), 16); // "ee

  const green = parseInt(customColor.substring(3, 5), 16); // "bb" -> 187

  const blue = parseInt(customColor.substring(5, 7), 16); // "cc" -> 204

  const selectedLightnessId = common.lightness.effectId;

  const defaultColors = [
    "#C42ED5",
    "#E13F94",
    "#E5685C",
    "#EF7B12",
    "#ECB51E",
    "#9ED414",
    "#3EA821",
    "#4770FF",
  ];

  function getGradientColor(startColor, endColor, ratio) {
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    const startRGB = hexToRgb(startColor);
    const endRGB = hexToRgb(endColor);

    const interpolated = startRGB.map((start, i) =>
      Math.round(start + (endRGB[i] - start) * ratio)
    );

    const rgbToHex = ([r, g, b]) =>
      `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("")}`;

    return rgbToHex(interpolated);
  }

  const lightnessArray = [
    {
      id: 9,
      icon: "/liuguang.png",
      text: t("lightness_Waves"),
    },
    {
      id: 10,
      icon: "/rainbow.png",
      text: t("lightness_Rainbow"),
    },
    {
      id: 11,
      icon: "/singledie.png",
      text: t("lightness_Extinction"),
    },
    {
      id: 12,
      icon: "/suibo.png",
      text: t("lightness_Light"),
    },
    {
      id: 13,
      icon: "/paoma.png",
      text: t("lightness_Running1"),
    },
    {
      id: 14,
      icon: "/guangpu.png",
      text: t("lightness_Spectral"),
    },
    {
      id: 15,
      icon: "/lianyi1.png",
      text: t("lightness_Ripple1"),
    },
    {
      id: 16,
      icon: "/scan.png",
      text: t("lightness_Scanning"),
    },
    {
      id: 17,
      icon: "/paoma2.png",
      text: t("lightness_Running2"),
    },
    {
      id: 0,
      icon: "/off.png",
      text: t("lightness_Off"),
    },
    {
      id: 1,
      icon: "/alwayson.png",
      text: t("lightness_Normal"),
    },
    {
      id: 2,
      icon: "/singlebreath.png",
      text: t("lightness_Breathing"),
    },
    {
      id: 3,
      icon: "/gear.png",
      text: t("lightness_Gear"),
    },
    {
      id: 4,
      icon: "/yicai.png",
      text: t("lightness_Streaming"),
    },
    {
      id: 5,
      icon: "/star.png",
      text: t("lightness_Starlight"),
    },
    {
      id: 6,
      icon: "/singleon.png",
      text: t("lightness_Single1"),
    },
    {
      id: 7,
      icon: "/singleon2.png",
      text: t("lightness_Single2"),
    },
    {
      id: 8,
      icon: "/lianyi2.png",
      text: t("lightness_Ripple2"),
    },
    {
      id: 18,
      icon: "/custom1.png",
      text: t("lightness_custom1"),
    },
    {
      id: 19,
      icon: "/custom2.png",
      text: t("lightness_custom2"),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "row",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: isLight ? "white" : "#252525",
        color: isLight ? "black" : "white",
        borderRadius: "8px",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      <div
        className="leftpart"
        style={{
          height: "100%",
          width: "50%",
          display: "flex",
          paddingRight: "2vh",
          flexDirection: "column",
          borderRight: "1px #ddd solid",
          marginRight: "4vh",
          alignItems: "flex-start",
        }}
      >
        <span style={{ margin: "1vh" }}>{t("lightness_select_mode")}</span>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {lightnessArray.map((item, index) => {
            return (
              <LightnessButton
                key={index}
                icon={item.icon}
                text={item.text}
                id={item.id}
                selected={selectedLightnessId === item.id}
                onClickEvent={(clicked) => {
                  if (clicked) {
                    dispatch(userClickLightnessAction({ effectid: item.id }));
                  }
                }}
              />
            );
          })}
        </div>
        <div
          className="bright_line"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: "0.7rem",
            width: "100%",
            margin: "1vh",
            padding: 0,
          }}
        >
          <span>{t("lightness_bright")}</span>
          <div style={{ width: "85%" }}>
            <CustomProgressBar
              currentValue={brightValue}
              setCurrentValue={(value) => {
                handleBrightChange(value);
              }}
            />
          </div>
        </div>

        <div
          className="speed_line"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: "0.7rem",
            width: "100%",
            margin: "2vh 1vh",
            padding: 0,
          }}
        >
          <span>{t("lightness_speed")}</span>
          <div style={{ width: "85%" }}>
            <CustomProgressBar
              currentValue={speedValue}
              setCurrentValue={(value) => {
                handleSpeedChange(value);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="rightpart"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          height: "100%",
          margin: "0.2vh",
        }}
      >
        <span>{t("lightness_custom_color")}</span>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "1.1vh",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            className="colorplate"
            style={{
              display: "flex",
              width: "35%",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%" }} onKeyUp={() => {}}>
              <Wheel
                color={hsva}
                onChange={(color) => {
                  setHsva({ ...hsva, ...color.hsva });
                  let hexColor = hsvaToHex({ ...hsva, ...color.hsva });

                  const halfwayColor = getGradientColor(
                    "#FFFFFF",
                    hexColor,
                    parseInt(colorProgress) / 100.0
                  );

                  dispatch(
                    userClickLightnessAction({
                      color: halfwayColor,
                      hexcolor: hexColor,
                    })
                  );
                }}
              />
            </div>
            <input
              className="color-slider"
              type="range"
              min="0"
              max="100"
              style={colorBlockStyle}
              value={colorProgress}
              step="1"
              onChange={(e) => {
                let value = parseInt(e.target.value);

                const halfwayColor = getGradientColor(
                  "#FFFFFF",
                  hexColor,
                  value / 100.0
                );

                dispatch(
                  userClickLightnessAction({
                    color: halfwayColor,
                    progress: e.target.value,
                  })
                );
              }}
            ></input>
          </div>

          <div
            className="color_board"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "45%",
              marginLeft: "3vh",
              alignItems: "flex-start",

              justifyContent: "space-around",
            }}
          >
            <span
              style={{
                backgroundColor: customColor,
                borderRadius: "5px",
                width: "40%",
                height: "3vh",
                textAlign: "center",
                userSelect: "text",
                padding: "0.3vh 1vh 0.3vh 1vh",
                lineHeight: "3vh",
              }}
            >
              {customColor}
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span>R</span>
              <span
                style={{
                  border: "#ccc 1px solid",
                  marginLeft: "2vh",
                  padding: "0.3vh 3vh",
                  borderRadius: "5px",
                  width: "3ch",
                }}
              >
                {red}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span>G</span>
              <span
                style={{
                  border: "#ccc 1px solid",
                  padding: "0.3vh 3vh",
                  borderRadius: "5px",
                  marginLeft: "2vh",
                  width: "3ch",
                }}
              >
                {green}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span>B</span>
              <span
                style={{
                  border: "#ccc 1px solid",
                  padding: "0.3vh 3vh",
                  marginLeft: "2vh",
                  borderRadius: "5px",
                  width: "3ch",
                }}
              >
                {blue}
              </span>
            </div>
          </div>
        </div>
        <span style={{ margin: "0.8vh 0" }}>
          {t("lightness_default_color")}
        </span>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          {defaultColors.map((item, index) => (
            <div
              key={index}
              style={{
                width: "2.3vh",
                margin: "0.8vh",
                borderRadius: "4px",
                height: "2.3vh",
                backgroundColor: item,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(
                  userClickLightnessAction({
                    progress: "100",
                    hexcolor: item,
                    color: item,
                  })
                );
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LightnessPage;

function CustomProgressBar({ currentValue, setCurrentValue }) {
  let dataArray = [0, 20, 40, 60, 80, 100];
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.getBoundingClientRect().width);
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "0.7vh",
        margin: "0 2vh",
        width: "100%",
        cursor: "pointer",
      }}
    >
      <div
        ref={divRef}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          height: "100%",
          alignItems: "center",
          marginRight: "0",

          background: `linear-gradient(90deg, #4368FF ${currentValue}%, gray ${currentValue}%)`,
        }}
        onClick={(event) => {
          const rect = divRef.current.getBoundingClientRect();
          const percentage = ((event.clientX - rect.left) / rect.width) * 100;

          let result = 0;
          if (percentage < 10) {
            result = 0;
          } else if (percentage < 30) {
            result = 20;
          } else if (percentage < 50) {
            result = 40;
          } else if (percentage < 70) {
            result = 60;
          } else if (percentage < 90) {
            result = 80;
          } else {
            result = 100;
          }

          setCurrentValue(result);
        }}
      >
        {dataArray.map((item, index) => (
          <span
            key={index}
            style={{
              padding: "0 1vh  0 1vh",
              //border: "1px solid red",
              position: "absolute",
              left: index * 20 + "%",
              transform: "translate(-50%, 0)",
              top: "120%",
              color:
                item === currentValue ? "#0042DA" : isLight ? "black" : "white",
              fontWeight: item === currentValue ? "bold" : "normal",
            }}
          >
            {item}%
          </span>
        ))}
      </div>
    </div>
  );
}

function LightnessButton({ icon, text, id, selected, onClickEvent }) {
  const dispatch = useDispatch();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  // 鼠标按下
  const handleMouseDown = useCallback((e) => {
    //dispatch(userMouseUpPosAction(-800, -800));
    console.log("handleMouseDown --> We begin to drag?", text, "id", id);
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "5px",

        backgroundColor: isLight ? "#F2f4f5" : "#242424",
        border: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
        color: isLight ? "black" : "white",
        width: "7.0vh",
        height: "6.2vh",
        fontSize: "0.5rem",
        margin: "1vh",
        justifyContent: "center",
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={(e) => {
        onClickEvent(true);
        //dispatch(userStopDragAction());
      }}
      onClick={(e) => {
        onClickEvent(!selected);
      }}
    >
      <img
        style={{ width: "2vh", height: "2vh", marginBottom: "1vh" }}
        src={icon}
      ></img>
      <span>{text}</span>
    </div>
  );
}
