import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userMouseDownCoordinate,
  userMouseUpPosAction,
  userStartDragAction,
  userStopDragAction,
} from "../webhid/action";

function ButtonUnit({ width, height, text, uid, selected, onClickEvent }) {
  const textSecs = text.split("\\n");

  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.currentConfigName;
  common = common[device];
  const dispatch = useDispatch();

  // separator line cap between first line and second line.
  if (width > 500) {
    width = 1;
    height = 0.3;
  }
  const newW = 4 * width;
  const newH = 4 * height;

  const extraWidth = (Math.floor(width + 0.9) - 1) * 4;

  let key = -1;
  // Numpad Enter, Numpad +
  if (uid === "00 58" || text === "+") {
    key = 2;
  }

  // 鼠标按下
  const handleMouseDown = useCallback((e) => {
    dispatch(userMouseUpPosAction(-800, -800));
    console.log(
      "handleMouseDown --> We begin to drag? (",
      e.clientX,
      e.clientY,
      ")",
      text,
      "uid",
      uid
    );

    dispatch(userMouseDownCoordinate(e.clientX, e.clientY));
    dispatch(userStartDragAction(text, uid));
  });

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={(e) => {
        onClickEvent(true);
        dispatch(userStopDragAction());
      }}
      style={{
        position: "relative",
        width: `calc(${newW}vh + ${extraWidth}px + 2px)`,
        height: `${newH}vh`,
        fontSize: "0.6rem",
        borderRadius: "4px",
        borderTopRightRadius: key === 0 ? 0 : "4px",
        borderTopLeftRadius: "4px",
        display: "flex",
        margin: newW >= 4 ? "2px" : `2px ${newW / 2}px`,
        flexDirection: "column",
        padding: "2px",
        border:
          text.length === 0
            ? "0px solid #0045d5"
            : selected
            ? "1px solid #0045d5"
            : "1px solid #e1e1e1",
      }}
      onClick={(e) => {
        onClickEvent(!selected);
      }}
    >
      {key > 0 && (
        <div
          style={{
            position: "absolute",
            width: "4.65vh",
            height: "5.4vh",
            right: "-1px" /* 右对齐 */,
            bottom: "-118%",
            borderLeft: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderRight: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderBottom: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",

            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            backgroundColor: isLight ? "white" : "#252525",
          }}
        ></div>
      )}

      {textSecs.map((item, index) => (
        <span key={index} style={{ userSelect: "none", fontWeight: "bold" }}>
          {item}
        </span>
      ))}
    </div>
  );
}

export default ButtonUnit;
