import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userClickTopMenuAction } from "../webhid/action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function TopMenuBar() {
  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.currentConfigName;
  common = common[device];

  const menuItem = [
    t("topmenu_button"),
    t("topmenu_light"),
    t("topmenu_trigger"),
    t("topmenu_superbtn"),
    t("topmenu_othersetting"),
  ];

  const menuIcon = [
    "/buttondef_gray.png",
    "/lightness_gray.png",
    "/quick_invoke_gray.png",
    "/superkey_gray.png",
    "/other_setting_gray.png",

    "/buttondef_white.png",
    "/lightness_white.png",
    "/quick_invoke_white.png",
    "/superkey_white.png",
    "/other_setting_white.png",
  ];
  const pathNav = [
    "/index/keymap",
    "/index/light",
    "/index/fasttrigger",
    "/index/superkey",
    "/index/settings",
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    navigate(pathNav[common.topMenuIndex]);
  }, [common.topMenuIndex]);

  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 0",
        marginBottom: "1vh",
        backgroundColor: isLight ? "white" : "#212023",
        borderRadius: "8px",
        // border: "1px #0043dd solid",
        justifyContent: "space-around",
      }}
    >
      {menuItem.map((item, index) => (
        <div
          style={{
            display: "flex",
            backgroundColor:
              common.topMenuIndex === index
                ? "#4368FF"
                : isLight
                ? "white"
                : "#202021",
            color:
              common.topMenuIndex === index
                ? "#FFF"
                : isLight
                ? "black"
                : "#666666",
            alignItems: "center",
            cursor: "pointer",
            //backgroundColor: "white",
            borderRadius: "5px",
            // border: "1px blue solid",
            padding: "0.6vh 2vh",
            justifyContent: "space-around",
          }}
          key={item}
          onClick={() => {
            dispatch(userClickTopMenuAction(index));
            navigate(pathNav[index]);
          }}
        >
          <img
            src={menuIcon[index]}
            style={{
              width: "3vh",
              height: "3vh",
              marginRight: "1vh",
            }}
          ></img>
          <span>{item}</span>
        </div>
      ))}
    </div>
  );
}

export default TopMenuBar;
