import { useSelector } from "react-redux";

function OtherToolsPage() {
  const common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  return (
    <div
      className="device-container"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        backgroundColor: isLight ? "#f2f4f5" : "#212023",
        color: isLight ? "black" : "white",
        width: "100%",
        padding: "3vh 4vh",
        margin: 0,
        flex: 1,
      }}
    >
      <div> OtherToolsPage </div>
      <div> OtherToolsPage </div>
    </div>
  );
}

export default OtherToolsPage;
