// 显示按键 ＋  uid + 浏览器事件code映射关系。
const keyMapData = [
  [
    { text: "Esc", uid: "00 29", keycode: "Escape" },
    { x: 1 },
    { text: "F1", uid: "00 3a", keycode: "F1" },
    { text: "F2", uid: "00 3b", keycode: "F2" },
    { text: "F3", uid: "00 3c", keycode: "F3" },
    { text: "F4", uid: "00 3d", keycode: "F4" },
    { x: 0.5 },
    { text: "F5", uid: "00 3e", keycode: "F5" },
    { text: "F6", uid: "00 3f", keycode: "F6" },
    { text: "F7", uid: "00 40", keycode: "F7" },
    { text: "F8", uid: "00 41", keycode: "F8" },
    { x: 0.5 },
    { text: "F9", uid: "00 42", keycode: "F9" },
    { text: "F10", uid: "00 43", keycode: "F10" },
    { text: "F11", uid: "00 44", keycode: "F11" }, // noaction
    { text: "F12", uid: "00 45", keycode: "F12" },
    { x: 0.25 },
    { text: "Print\\nScreen", uid: "00 46", keycode: "PrintScreen" },
    { text: "Scroll\\nLock", uid: "00 47", keycode: "ScrollLock" },
    { text: "Pause\\nBreak", uid: "00 48", keycode: "Pause" },
  ],
  [{ x: 800 }], //separator
  [
    { text: "~\\n`", uid: "00 32", keycode: "Backquote" }, //ROCK
    { text: "!\\n1", uid: "00 1e", keycode: "Digit1" },
    { text: "@\\n2", uid: "00 1f", keycode: "Digit2" },
    { text: "#\\n3", uid: "00 20", keycode: "Digit3" },
    { text: "$\\n4", uid: "00 21", keycode: "Digit4" },
    { text: "%\\n5", uid: "00 22", keycode: "Digit5" },
    { text: "^\\n6", uid: "00 23", keycode: "Digit6" },
    { text: "&\\n7", uid: "00 24", keycode: "Digit7" },
    { text: "*\\n8", uid: "00 25", keycode: "Digit8" },
    { text: "(\\n9", uid: "00 26", keycode: "Digit9" },
    { text: ")\\n0", uid: "00 27", keycode: "Digit0" },
    { text: "_\\n-", uid: "00 2d", keycode: "Minus" },
    { text: "+\\n=", uid: "00 2e", keycode: "Equal" },
    { w: 2.18 },
    { text: "Backspace", uid: "00 2a", keycode: "Backspace" },
    { x: 0.25 },
    { text: "Insert", uid: "00 49", keycode: "Insert" },
    { text: "Home", uid: "00 4a", keycode: "Home" },
    { text: "PgUp", uid: "00 4b", keycode: "PageUp" },
    { x: 0.25 },
    { text: "Num\\nLock", uid: "00 53", keycode: "NumLock" },
    { text: "/", uid: "00 54", keycode: "NumpadDivide" },
    { text: "*", uid: "00 55", keycode: "NumpadMultiply" },
    { text: "-", uid: "00 56", keycode: "NumpadSubtract" },
  ],
  [
    { w: 1.5 },
    { text: "Tab", uid: "00 2b", keycode: "Tab" },
    { text: "Q", uid: "00 14", keycode: "KeyQ" },
    { text: "W", uid: "00 1a", keycode: "KeyW" },
    { text: "E", uid: "00 08", keycode: "KeyE" },
    { text: "R", uid: "00 15", keycode: "KeyR" },
    { text: "T", uid: "00 17", keycode: "KeyT" },
    { text: "Y", uid: "00 1c", keycode: "KeyY" },
    { text: "U", uid: "00 18", keycode: "KeyU" },
    { text: "I", uid: "00 0c", keycode: "KeyI" },
    { text: "O", uid: "00 12", keycode: "KeyO" },
    { text: "P", uid: "00 13", keycode: "KeyP" },
    { text: "{\\n[", uid: "00 2f", keycode: "BracketLeft" },
    { text: "}\\n]", uid: "00 30", keycode: "BracketRight" },
    { w: 1.68 },
    { text: "|\\n\\", uid: "00 31", keycode: "Backslash" },
    // { w: 1.5 },
    //{ x: 1.75 },
    { x: 0.25 },
    { w: 1 },
    { text: "Delete", uid: "00 4c", keycode: "Delete" },
    { text: "End", uid: "00 4d", keycode: "End" },
    { text: "PgDn", uid: "00 4e", keycode: "PageDown" },
    { x: 0.25 },
    { text: "7\\nHome", uid: "00 5f", keycode: "Numpad7" },
    { text: "8\\n↑", uid: "00 60", keycode: "Numpad8" },
    { text: "9\\nPgUp", uid: "00 61", keycode: "Numpad9" },
    { h: 2 },
    { text: "+", uid: "00 57", keycode: "NumpadAdd" },
  ],
  [
    { w: 1.75 },
    { text: "Caps Lock", uid: "00 39", keycode: "CapsLock" },
    { text: "A", uid: "00 04", keycode: "KeyA" },
    { text: "S", uid: "00 16", keycode: "KeyS" },
    { text: "D", uid: "00 07", keycode: "KeyD" },
    { text: "F", uid: "00 09", keycode: "KeyF" },
    { text: "G", uid: "00 0a", keycode: "KeyG" },
    { text: "H", uid: "00 0b", keycode: "KeyH" },
    { text: "J", uid: "00 0d", keycode: "KeyJ" },
    { text: "K", uid: "00 0e", keycode: "KeyK" },
    { text: "L", uid: "00 0f", keycode: "KeyL" },
    { text: ":\\n;", uid: "00 33", keycode: "Semicolon" },
    { text: "\"\\n'", uid: "00 34", keycode: "Quote" },
    { w: 2.63 },
    { text: "Enter", uid: "00 28", keycode: "Enter" },
    { x: 4.19 },
    { text: "4\\n←", uid: "00 5c", keycode: "Numpad4" },
    { text: "5", uid: "00 5d", keycode: "Numpad5" },
    { text: "6\\n→", uid: "00 5e", keycode: "Numpad6" },
  ],
  [
    { w: 2.35 },
    { text: "Shift", uid: "00 e1", keycode: "ShiftLeft" },
    { text: "Z", uid: "00 1d", keycode: "KeyZ" },
    { text: "X", uid: "00 1b", keycode: "KeyX" },
    { text: "C", uid: "00 06", keycode: "KeyC" },
    { text: "V", uid: "00 19", keycode: "KeyV" },
    { text: "B", uid: "00 05", keycode: "KeyB" },
    { text: "N", uid: "00 11", keycode: "KeyN" },
    { text: "M", uid: "00 10", keycode: "KeyM" },
    { text: "<\\n,", uid: "00 36", keycode: "Comma" },
    { text: ">\\n.", uid: "00 37", keycode: "Period" },
    { text: "?\\n/", uid: "00 38", keycode: "Slash" },
    { w: 3.15 },
    { text: "Shift", uid: "00 e5", keycode: "ShiftRight" },
    { x: 1.43 },
    { text: "↑", uid: "00 52", keycode: "ArrowUp" },
    { x: 1.35 },
    { text: "1\\nEnd", uid: "00 59", keycode: "Numpad1" },
    { text: "2\\n↓", uid: "00 5a", keycode: "Numpad2" },
    { text: "3\\nPgDn", uid: "00 5b", keycode: "Numpad3" },
    { h: 2 },
    { text: "Enter", uid: "00 58", keycode: "NumpadEnter" },
  ],
  [
    { w: 1.4 },
    { text: "Ctrl", uid: "00 e0", keycode: "ControlLeft" },
    { w: 1.4 },
    { text: "Win", uid: "00 e3", keycode: "WinLeft" }, // no action.
    { w: 1.4 },
    { text: "Alt", uid: "00 e2", keycode: "AltLeft" },
    { a: 7, w: 7.57 },
    { text: "Space", uid: "00 cd", keycode: "Space" },
    { text: "Alt", uid: "00 e6", keycode: "AltRight" },
    { w: 1.4 },
    { text: "Win", uid: "00 e7", keycode: "WinRight" }, // noaction.

    { text: "Fn", uid: "FF e4", keycode: "ControlRight" }, //TODO

    // { w: 1.4 },
    { text: "Ctrl", uid: "00 e4", keycode: "ControlRight" },
    { x: 0.25 },
    { text: "←", uid: "00 50", keycode: "ArrowLeft" },
    { text: "↓", uid: "00 51", keycode: "ArrowDown" }, //▼ ▲ ◀ ▶
    { text: "→", uid: "00 4f", keycode: "ArrowRight" },
    { x: 0.25 },
    { w: 2.1 },
    { text: "0\\nIns", uid: "00 62", keycode: "Numpad0" },
    { text: ".\\nDel", uid: "00 63", keycode: "NumpadDecimal" },
  ],
];
function getShowTextByKeyCode(code) {
  for (let i = 0; i < keyMapData.length; i++) {
    for (let j = 0; j < keyMapData[i].length; j++) {
      if (keyMapData[i][j].keycode === code) {
        return keyMapData[i][j].text;
      }
    }
  }
}

function getShowTextByUid(uid) {
  for (let i = 0; i < keyMapData.length; i++) {
    for (let j = 0; j < keyMapData[i].length; j++) {
      if (keyMapData[i][j].uid === uid) {
        return keyMapData[i][j].text;
      }
    }
  }
}

function getUidByKeyCode(code) {
  for (let i = 0; i < keyMapData.length; i++) {
    for (let j = 0; j < keyMapData[i].length; j++) {
      if (keyMapData[i][j].keycode === code) {
        return keyMapData[i][j].uid;
      }
    }
  }
}

function getUidByText(text) {
  for (let i = 0; i < keyMapData.length; i++) {
    for (let j = 0; j < keyMapData[i].length; j++) {
      if (keyMapData[i][j].text === text) {
        return keyMapData[i][j].uid;
      }
    }
  }
}
export {
  keyMapData,
  getUidByKeyCode,
  getShowTextByKeyCode,
  getShowTextByUid,
  getUidByText,
};
